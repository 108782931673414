import React, { Component } from "react"
import { isMobile } from "react-device-detect"
import { NavLink } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import classnames from "classnames"

class CustomMenu extends Component {
  render() {
    return (
      this.props.menu.isExternal ?
        <a
          href={this.props.menu.redirectUrl}
          target='_blank'
          rel="noopener noreferrer"
          className="header-item"
        >
          <span data-tip={this.props.menu.title} data-for={this.props.menu.title}>
            {isMobile ? this.props.menu.mobileDisplay : this.props.menu.display}
          </span>
          {isMobile
            ? null
            : <ReactTooltip className="tooltip-clearout" id={this.props.menu.title} place="bottom" />
          }
        </a> :
        <NavLink
          activeClassName="active"
          className={classnames('header-item', this.props.menu.showBadge ? 'header-with-badge' : null)}
          to={this.props.menu.redirectUrl}
          onClick={this.props.closeNavbar}
        >

          <span data-tip={this.props.menu.title} data-for={this.props.menu.title}>
            {this.props.menu.showBadge && !isMobile
              ? <span className="badge header-new-badge">New</span>
              : this.props.menu.showBadge && isMobile
                ? <span className="badge mobile-header-new-badge">
                  <i className="fa fa-circle" aria-hidden="true"></i>
                </span>
                : null
            }
            {isMobile ?
              this.props.menu.mobileDisplay
              : this.props.menu.display}
          </span>
          {isMobile
            ? null
            : <ReactTooltip className="tooltip-clearout" id={this.props.menu.title} place="bottom" />
          }
        </NavLink>
    )
  }
}

export default CustomMenu