const LOGIN_SUCCESS = 'login-success'
const Login_REQUEST_STARTED = 'login-request-started'
const LOGIN_FAILED = 'login-failed'
const LOGOUT_SUCCESS = "logout-success"
const UPDATE_USER_EMAIL = 'update-email-address'
const UPDATE_CREDITS = 'update-credits'
const UPDATE_ORG_CREDITS = 'update-org-credits'
const UPDATE_USER_NAME = 'update-username'
const UPDATE_USER_ADDRESS = 'update-address'
const UPDATE_LOCALE = 'update-locale'
const UPDATE_VALID_CARD = 'update-valid-card'
const UPDATE_INTEGRATION_STATUS = 'update-integration-status'
const UPDATE_USER_LIMITS = 'update-user-limits'
const UPDATE_ORG_NAME = 'update-org-name'
const UPDATE_ORG_URL = 'update-org-url'
const UPDATE_ORG_TEL = 'update-org-tel'
const UPDATE_SHOW_DAILY_LIMIT = 'update-show-daily-limit'
const UPDATE_ORG_LIMITS = 'update-org-limits'
const UPDATE_USER = 'update-user'
const UPDATE_CURRENT_PLAN = 'update-current-plan'
const UPDATE_USER_PHONE = 'update-user-phone'
const SHOW_STRIPE = 's-s'
const WELCOME_POPUP_OPENED = false
const UPDATE_USER_PASSWORD = 'update-user-password'

const initialState = {
  id: 0,
  email: '',
  name: 'User',
  address: {},
  isLogged: false,
  jwtToken: null,
  restoreId: null,
  isLoginSuccess: false,
  isEmailVerified: false,
  credits: null,
  isValidCard: false,
  integrations: {},
  user_role: '',
  limits: {},
  org_name: '',
  org_url: '',
  org_tel: '',
  org_credits: {},
  org_limits: {},
  current_plan: {},
  phone_number: '',
  activated_on: '',
  performance_stats: {},
  sS: false,
  welcomePopupOpened: false,
  has_password: false
}


export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {

    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        address: action.payload.address ? action.payload.address : {},
        jwtToken: action.payload.token,
        isLoginSuccess: true,
        restoreId: action.payload.fresh_chat,
        isLogged: true,
        isEmailVerified: action.payload.email_verified,
        credits: action.payload.credits,
        locale: action.payload.locale,
        isValidCard: action.payload.is_valid_card ? action.payload.is_valid_card : false,
        integrations: action.payload.integrations,
        user_role: action.payload.user_role,
        org_id: action.payload.org_id,
        user_type: action.payload.user_type,
        org_name: Object.keys(action.payload.org_details).length !== 0 ? action.payload.org_details.name : '',
        org_url: Object.keys(action.payload.org_details).length !== 0 ? action.payload.org_details.org_website_url : '',
        org_tel: Object.keys(action.payload.org_details).length !== 0 ? action.payload.org_details.org_tel : '',
        org_credits: Object.keys(action.payload.org_details).length !== 0 ? action.payload.org_details.credits : '',
        org_limits: Object.keys(action.payload.org_details).length !== 0 ? action.payload.org_details.limits : '',
        show_daily_limits: action.payload.show_daily_limits,
        current_plan: action.payload.subscription ? action.payload.subscription : {},
        phone_number: action.payload.phone_number,
        activated_on: action.payload.activated_on,
        recur: action.payload.recur ? action.payload.recur : null,
        org_recur: Object.keys(action.payload.org_details).length !== 0 ? action.payload.org_details.recur : null,
        performance_stats: action.payload.performance_stats,
        limits: action.payload.limits,
      })


    case Login_REQUEST_STARTED:
      return Object.assign({}, state, { isLogged: true })

    case LOGIN_FAILED:
      return Object.assign({}, state, initialState)


    case LOGOUT_SUCCESS:
      return Object.assign({}, state, initialState)

    case UPDATE_USER_EMAIL:
      return Object.assign({}, state, {
        email: action.payload,
      })

    case UPDATE_USER_ADDRESS:
      return Object.assign({}, state, {
        address: action.payload,
      })

    case UPDATE_CREDITS:
      return Object.assign({}, state, {
        credits: action.payload,
      })

    case UPDATE_ORG_CREDITS:
      return Object.assign({}, state, {
        org_credits: action.payload,
      })

    case UPDATE_ORG_LIMITS:
      return Object.assign({}, state, {
        org_limits: action.payload,
      })

    case UPDATE_USER_NAME:
      return Object.assign({}, state, {
        name: action.payload,
      })

    case UPDATE_ORG_NAME:
      return Object.assign({}, state, {
        org_name: action.payload,
      })

    case UPDATE_ORG_URL:
      return Object.assign({}, state, {
        org_url: action.payload,
      })

    case UPDATE_ORG_TEL:
      return Object.assign({}, state, {
        org_tel: action.payload,
      })

    case UPDATE_LOCALE:
      return Object.assign({}, state, {
        locale: action.payload,
      })

    case UPDATE_VALID_CARD:
      return Object.assign({}, state, {
        isValidCard: action.payload,
      })

    case UPDATE_SHOW_DAILY_LIMIT:
      return Object.assign({}, state, {
        show_daily_limits: action.payload,
      })

    case UPDATE_INTEGRATION_STATUS:
      return Object.assign({}, state, {
        integrations: action.payload
      })

    case UPDATE_USER_LIMITS:
      return Object.assign({}, state, {
        limits: action.payload
      })

    case UPDATE_USER:
      return Object.assign({}, state, { ...action.payload })

    case UPDATE_CURRENT_PLAN:
      return Object.assign({}, state, {
        current_plan: action.payload
      })

    case UPDATE_USER_PHONE:
      return Object.assign({}, state, {
        phone_number: action.payload
      })

    case SHOW_STRIPE:
      return Object.assign({}, state, {
        sS: action.payload
      })

    case WELCOME_POPUP_OPENED:
      return Object.assign({}, state, {
        welcomePopupOpened: action.payload
      })

    case UPDATE_USER_PASSWORD:
      return Object.assign({}, state, {
        has_password: action.payload
      })

    default:
      return state
  }
}

export function loginSuccess(payload) {
  return { type: LOGIN_SUCCESS, payload }
}

export function loginFailed(payload) {
  return { type: LOGIN_FAILED, payload }
}

export function logoutSuccess(payload) {
  return {
    type: LOGOUT_SUCCESS,
    payload
  }
}

export function updateUserEmail(payload) {
  return {
    type: UPDATE_USER_EMAIL,
    payload
  }
}

export function updateUserName(payload) {
  // appEventEmitter.emit(AppEvents.username_update_success, { ...payload, app_version: APP_VERSION })
  return {
    type: UPDATE_USER_NAME,
    payload
  }
}

export function updateUserAddress(payload) {
  return {
    type: UPDATE_USER_ADDRESS,
    payload
  }
}

export function updateOrgName(payload) {
  return {
    type: UPDATE_ORG_NAME,
    payload
  }
}

export function updateOrgUrl(payload) {
  return {
    type: UPDATE_ORG_URL,
    payload
  }
}

export function updateOrgTel(payload) {
  return {
    type: UPDATE_ORG_TEL,
    payload
  }
}

export function updateCredits(payload) {
  return {
    type: UPDATE_CREDITS,
    payload
  }
}

export function updateOrgLimits(payload) {
  return {
    type: UPDATE_ORG_LIMITS,
    payload
  }
}

export function updateOrgCredits(payload) {
  return {
    type: UPDATE_ORG_CREDITS,
    payload
  }
}

export function updateUserLocale(payload) {
  return {
    type: UPDATE_LOCALE,
    payload
  }
}

export function updateValidCardExists(payload) {
  return {
    type: UPDATE_VALID_CARD,
    payload
  }
}

export function updateShowDailyLimits(payload) {
  return {
    type: UPDATE_SHOW_DAILY_LIMIT,
    payload
  }
}

export function updateIntegrationStatus(payload) {
  return {
    type: UPDATE_INTEGRATION_STATUS,
    payload
  }
}

export function updateUserLimits(payload) {
  return {
    type: UPDATE_USER_LIMITS,
    payload
  }
}

export function updateUserCurrentPlan(payload) {
  return {
    type: UPDATE_CURRENT_PLAN,
    payload
  }
}

export function updateUser(payload) {
  return {
    type: UPDATE_USER,
    payload
  }
}

export function updateUserPhone(payload) {
  return {
    type: UPDATE_USER_PHONE,
    payload
  }
}

export function showStripe(payload) {
  return {
    type: SHOW_STRIPE,
    payload
  }
}

export function updateWelcomeModalOpenedStatus(payload) {
  return {
    type: WELCOME_POPUP_OPENED,
    payload
  }
}

export function updateUserPassword(payload) {
  return {
    type: UPDATE_USER_PASSWORD,
    payload
  }
}
