import React from 'react'
import ReactDOM from 'react-dom'
import Loadable from 'react-loadable'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { Provider } from 'react-redux'
import deepExtend from 'deep-extend'
import DevTools from './helpers/DevTools'
import reducer from './reducer'
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync"
import TagManager from 'react-gtm-module'

import GoogleAnalyticsTracker from './helpers/googleAnalyticsTracker'
import MatomoTracker from './helpers/matomoTracker'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

// eslint-disable-next-line
import Bootstrap from 'bootstrap/dist/css/bootstrap.css'


// import * as serviceWorker from './serviceWorker'
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

import 'bootstrap-daterangepicker/daterangepicker.css'
import './static/css/clearout.css'
import './static/css/clearout-theme.css'
import './static/css/font-awesome.css'
import './static/css/ionicons.css'
import './static/css/instant_search.css'
import './static/css/quick-validation.css'

// init Google tag manager
if (GTM_ID) {
  TagManager.initialize({
    gtmId: GTM_ID
  })
}

// init GA4
GoogleAnalyticsTracker.init()

//init Matomo
MatomoTracker.init()

const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName: "Dashboard" */
    './components/dashboard'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Loading...</div>
  }
})

const Login = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */
    './components/auth/login'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Loading...</div>
  }
})

const Register = Loadable({
  loader: () => import(/* webpackChunkName: "Signup" */
    './components/auth/register'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Loading...</div>
  }
})

const PublicInstantSearch = Loadable({
  loader: () => import(/* webpackChunkName: "PublicInstantSearch" */
    './components/instantsearch/public_instant_search'),
  loading: function Loading() {
    return <div id='react-loadable' className='row react-loadable-loading' >Loading...</div>
  }
})

const PublicCredits = Loadable({
  loader: () => import(/* webpackChunkName: "PublicCredits" */
    './components/account/public_credits'),
  loading: function Loading() {
    return <div id='react-loadable' className='row react-loadable-loading' >Loading...</div>
  }
})

const SignUp = Loadable({
  loader: () => import(/* webpackChunkName: "SignUpAuthWrapper" */
    './components/auth/signup'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Loading...</div>
  }
})

const AdminSignup = Loadable({
  loader: () => import(/* webpackChunkName: "SignUpAuthWrapper" */
    './components/auth/admin_register'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Loading...</div>
  }
})

const ResetPassword = Loadable({
  loader: () => import(/* webpackChunkName: "ResetPassword" */
    './components/auth/reset_password'),
  loading: function Loading() {
    return <div id='react-loadable' className='row react-loadable-loading' >Loading...</div>
  }
})

const EmailVerified = Loadable({
  loader: () => import(/* webpackChunkName: "EmailVerified" */
    './components/auth/email_verified'),
  loading: function Loading() {
    return <div id='react-loadable' className='row react-loadable-loading' >Loading...</div>
  }
})

const ForgotPassword = Loadable({
  loader: () => import(/* webpackChunkName: "SignUpAuthWrapper" */
    './components/auth/forgot_password'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Loading...</div>
  }
})

const Download = Loadable({
  loader: () => import(/* webpackChunkName: "Download" */
    './components/download/download'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Downloading...</div>
  }
})

const DownloadWithoutPolling = Loadable({
  loader: () => import(/* webpackChunkName: "DownloadWithoutPolling" */
    './components/download/download_without_polling'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Downloading...</div>
  }
})

const download_input_file = Loadable({
  loader: () => import(/* webpackChunkName: "Download Input file" */
    './components/download/download_input_file'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Downloading...</div>
  }
})

const PerformanceStats = Loadable({
  loader: () => import(/* webpackChunkName: "PerformanceStats" */
    './components/performance_stats'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Loading...</div>
  }
})

const OneToolLogin = Loadable({
  loader: () => import(/* webpackChunkName: "OneToolLogin" */
    './components/auth/onetool_login'),
  loading: function Loading() {
    return <div id='react-loadable' className='row react-loadable-loading' >Loading...</div>
  }
})

const DownloadProspects = Loadable({
  loader: () => import(/* webpackChunkName: "DownloadProspects" */
    './components/prospect/components/download/download_prospects'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Downloading...</div>
  }
})

const SSOSignup = Loadable({
  loader: () => import(/* webpackChunkName: "SSOSignup" */
    './components/auth/sso/signup'),
  loading: function Loading() {
    return <div id='react-loadable' className='row react-loadable-loading' >Loading...</div>
  }
})

var middlewares = []
middlewares.push(thunkMiddleware)
if (process.env.NODE_ENV !== "production") {
  const loggerMiddleware = createLogger()
  middlewares.push(loggerMiddleware)
}

const enhancer = compose(
  // Middleware you want to use in development: thunkMiddleware - lets us
  // dispatch() functions loggerMiddleware - neat middleware that logs actions
  // routingMiddleware - to capture dispatched actions created by the action
  // creators
  applyMiddleware(createStateSyncMiddleware()),
  applyMiddleware(...middlewares), DevTools.instrument())

const rootReducer = (state, action) => {
  if (action.type === 'logout-success') {
    state = undefined
  }
  return reducer(state, action)
}

const persistedState = localStorage.getItem(APP_NAME)
  ? JSON.parse(localStorage.getItem(APP_NAME))
  : {}

let initialState = deepExtend(createStore(rootReducer, enhancer).getState(), persistedState)
const store = createStore(rootReducer, initialState, enhancer)

initMessageListener(store)

store.subscribe(() => {
  localStorage.setItem(APP_NAME, JSON.stringify(store.getState()))
})

const locationHelper = locationHelperBuilder({})


var queryParams = window.location.search

const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login' + queryParams,
  // Determine if the user is authenticated or not
  authenticatedSelector: state => state.userReducer.isLogged === true,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated'
})


const userIsNotAuthenticated = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the
  // landing page if none is specified and the user is already logged in
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/dashboard' + queryParams,
  // This prevents us from adding the query parameter when we send the user away
  // from the login page
  allowRedirectBack: false,
  // Determine if the user is authenticated or not
  authenticatedSelector: state => state.userReducer.isLogged === false,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsNotAuthenticated'
})

const DashboardAuthWrapper = userIsAuthenticated(Dashboard)
const LoginAuthWrapper = userIsNotAuthenticated(Login)
const SignUpAuthWrapper = userIsNotAuthenticated(SignUp)
// const AdminSignUpAuthWrapper = userIsNotAuthenticated(AdminSignup)
const OnetoolAuthWrapper = userIsNotAuthenticated(OneToolLogin)
const ForgotPasswordWrapper = userIsNotAuthenticated(ForgotPassword)
const SSOSignUpAuthWrapper = userIsNotAuthenticated(SSOSignup)
const RegisterAuthWrapper = userIsNotAuthenticated(Register)

const NotFoundRedirect = () => {
  return < Redirect to='/' />
}

const App = () => (
  <Switch>
    <Route exact path='/'>
      <Redirect to={"/login" + window.location.search} />
    </Route>
    <Route path='/dashboard' component={DashboardAuthWrapper} />
    <Route path='/login' component={LoginAuthWrapper} />
    {/* <Route path='/login_new' component={LoginNewAuthWrapper} /> */}
    <Route path='/register' component={RegisterAuthWrapper} />
    {/* <Route path='/register_new' component={RegisterNewAuthWrapper}/> */}
    <Route path='/signup' component={SignUpAuthWrapper} />
    <Route path='/enterprise/register' component={AdminSignup} />
    <Route path='/forgot_password' component={ForgotPasswordWrapper} />
    <Route path='/public/instant_search' component={PublicInstantSearch} />
    <Route path='/public/credits' component={PublicCredits} />
    <Route path='/resetpassword' component={ResetPassword} />
    <Route path='/emailverification' component={EmailVerified} />
    <Route path='/download_result/:download_id' component={Download} />
    <Route path='/download/result' component={DownloadWithoutPolling} />
    <Route path='/input_download/:request_id' component={download_input_file} />
    <Route path='/public/marketing_stats/' component={PerformanceStats} />
    <Route path='/onetool_login' component={OnetoolAuthWrapper} />
    <Route path='/download_email_finder_history' component={DownloadWithoutPolling} />
    <Route path='/download/email_finder_result' component={DownloadWithoutPolling} />
    <Route path='/download_leads' component={DownloadWithoutPolling} />
    <Route path='/download_leads_in_list' component={DownloadWithoutPolling} />
    <Route path='/download_prospects' component={DownloadProspects} />
    <Route path='/ssosignup' component={SSOSignUpAuthWrapper} />
    <Route component={NotFoundRedirect} />
  </Switch>
)


const logPageView = () => {
  // GP - For GA4 page view event will be part of Enhanced Measurement events, 
  // so need to explicity fire the events. This setting can be found in the GA->Data Streams
  // Send pageview with a custom path
  // GoogleAnalyticsTracker.send({
  //   hitType: "pageview",
  //   page: window.location.pathname + window.location.search,
  //   title: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
  // })
  return null;
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_V3_KEY}>
        <div>
          <Route path="/" component={App} />
          <Route path="/" component={logPageView} />
        </div>
      </GoogleReCaptchaProvider>
    </Router>
  </Provider>,
  document.getElementById('root'));


// serviceWorker.unregister();
