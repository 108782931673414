import React, { useState, useCallback } from 'react'
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import ReactSVG from "react-svg"
import ReactTooltip from 'react-tooltip'
import { isMobile } from "react-device-detect"

import ApiService from '../../helpers/apiservice'
import { Notify } from '../notifications'
import appEventEmitter, { AppEvents } from '../../helpers/AppEventEmitter'

import * as Constants from '../../helpers/constants'

const G2CrowdModal = (props) => {

  const [modal, setModal] = useState(false)
  const [iFrameUrl, setIframeUrl] = useState("")
  const [showSpinner, setShowSpinner] = useState(true)

  const email = props.user.email

  const getG2CrowdUrl = useCallback(
    () => {
      let queryParams = `?email=${email}`
      ApiService.getG2CrowdUrl(queryParams)
        .then(res => {
          if (res.status == Constants.CO_API.Success) {
            setIframeUrl(res.data.url)
            setModal(true)
          } else if (res.status == Constants.CO_API.Failed) {
            Notify().showNotification({
              title: "G2Crowd Review",
              message: 'Something went wrong',
              level: "error",
            })
          }
        })
        .catch(err => {
          console.log("g2 crowd url api erred", err)
        })
    },
    [email]
  )

  const toggle = () => {
    setModal(!modal)
  }

  const onModalClosed = () => {
    setModal(false) // Call the provided toggle function
    setIframeUrl("")

    appEventEmitter.emit(AppEvents.user_g2crowd_modal_close, {
      app_version: APP_VERSION,
    })
  }

  const onModalOpened = () => {
    setShowSpinner(true)

    appEventEmitter.emit(AppEvents.user_g2crowd_modal_open, {
      app_version: APP_VERSION,
    })
  }

  return (
    <React.Fragment>
      <li
        className='nav-item widget-nav'
        key="g2-review-button"
      >
        <a
          className='header-item header-with-badge'
          onClick={() => getG2CrowdUrl()}
          data-tip="Please write a review to earn 500 free credits"
          data-for="g2"
        >
          <span className='g2crowd-btn'>
            {isMobile
              ? null
              : <span className="badge header-new-badge">New</span>}
            <span>Review us on</span>
            <ReactSVG className='header-g2-icon' src={require('../../static/svg/g2crowd.svg')} />
          </span>
        </a>
        {isMobile
          ? null
          : <ReactTooltip className="tooltip-clearout" id="g2" place="bottom" multiline={true} />}
      </li>

      <Modal
        className='iframe-container'
        isOpen={modal}
        toggle={toggle}
        onClosed={onModalClosed}
        onOpened={onModalOpened}
        centered={true}
        size='lg'
      >
        <ModalHeader
          toggle={toggle}
        >
          G2 Review
        </ModalHeader>
        <ModalBody>
          {showSpinner
            ? <div className='g2-header-spinner-container'>
              <Spinner />
            </div>
            : null}
          <iframe
            id="g2crowd-iframe"
            height="100%"
            width="100%"
            src={iFrameUrl}
            onLoad={() => {
              setShowSpinner(false)
            }}
          >
          </iframe>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default G2CrowdModal
