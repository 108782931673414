import ReactGA from "react-ga4"
import _ from "lodash"

import appEventEmitter, { AppEvents } from './AppEventEmitter'
import { getRandomId, getUserIdFromLocalStorage } from '../helpers/helper'

const GoogleAnalyticsTracker = {
  isInitialized: false,

  _registerEvent: function (eventName, callback) {
    return appEventEmitter.addListener(eventName, callback)
  },

  _unRegisterEvents: function () {
    // todo
  },

  init: function () {
    if (this.isInitialized)
      return

    if (!GA4_MEASUREMENT_ID) {
      console.warn('Google Analytics not initialized, since measurement id not supplied')
      return
    }

    console.debug('Google Analytics initialized with measurement id:', GA4_MEASUREMENT_ID)
    ReactGA.initialize(GA4_MEASUREMENT_ID, {
      testMode: false,
      gaOptions: {
        trackingId: GA_TRACKING_ID,
        currencyCode: 'USD',
        debug_mode: false,
      },
      gtagOptions: {
        currency: 'USD',
      }
    })

    this._registerEvent(AppEvents.signup_success, (e) => {
      let user_id = _.get(e, 'user_id', null)
      let email = _.get(e, 'email', null)
      let method = _.get(e, 'signup_method', 'email')
      let source = _.get(e, 'signup_source', 'direct')
      ReactGA.event('sign_up', { method, source, user_id, email, co_user_id: user_id })
      ReactGA.event('sign_up', { method, source, user_id, email, co_user_id: user_id, send_to: GA_TRACKING_ID })
    })

    this._registerEvent(AppEvents.user_login_success, (e) => {
      let user_id = _.get(e, 'user_id', null)
      let email = _.get(e, 'email', null)
      let method = _.get(e, 'signin_method', 'email')
      let source = _.get(e, 'signin_source', 'direct')

      ReactGA.set({ user_id })
      ReactGA.event('login', { method, source, email, co_user_id: user_id })
      ReactGA.event('login', { method, source, email, co_user_id: user_id, send_to: GA_TRACKING_ID })
    })

    this._registerEvent(AppEvents.user_clicked_logout_btn, () => {
      let user_id = null
      ReactGA.set({ user_id })
      ReactGA.event('logout', { co_user_id: user_id })
      ReactGA.event('logout', { co_user_id: user_id, send_to: GA_TRACKING_ID })
    })

    this._registerEvent(AppEvents.user_payment_success, (e) => {
      let value = _.get(e.eventData, 'amount', 0)
      let credits = _.get(e.eventData, 'credits', 0)
      let recurring = _.get(e.eventData, 'recurring', false)
      let transaction_id = _.get(e.eventData, 'transaction_id', `txn_${getRandomId()}`)
      let items = _.get(e.eventData, 'items', [{ item_id: 'SKU_12345', item_name: 'clearout credits', quantity: credits, price: value }])
      let payment_gateway = _.get(e.eventData, 'payment_gateway', 'unknown')

      let user_id = getUserIdFromLocalStorage()
      ReactGA.event('purchase', { value, credits, payment_gateway, recurring, transaction_id, items, co_user_id: user_id })
      ReactGA.event('purchase', { value, credits, payment_gateway, recurring, transaction_id, items, co_user_id: user_id, send_to: GA_TRACKING_ID })
    })

    this.isInitialized = true
  },

  send: function ({ hitType, page_location, page_title }) {
    ReactGA.send({ hitType, page_location, page_title })
  }
}

export default GoogleAnalyticsTracker
