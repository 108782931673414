import React from 'react'
import * as Constants from './constants'

import ReactSVG from "react-svg"


export const MENUS = {
  dashboard: {
    display: 'Dashboard',
    redirectUrl: '/dashboard/overview',
    title: '',
    mobileDisplay: <i className="fa fa-tachometer" />,
    value: 'dashboard',
    locator: 'header',
    mobileLocator: 'none'
  },
  my_lists: {
    display: 'Email Verifier',
    redirectUrl: '/dashboard/email-verifier',
    title: 'Verify email addresses in bulk or instant',
    mobileDisplay: <img className="list-png" src={require("../static/img/email_verify.png")} alt="Email Verify" />,
    value: 'my_lists',
    locator: "header",
    mobileLocator: 'header'
  },
  // leads : {
  //   display : 'Lead Finder',
  //   redirectUrl : '/dashboard/leads',
  //   title : '',
  //   mobileDisplay : 'Lead Finder',
  //   value : 'leads'
  // },
  integrations: {
    display: <span className="header-dropdown-icon">
      <i className="fa fa-th"></i>
      <span>Integrations</span></span>,
    redirectUrl: '/dashboard/integrations',
    title: 'Connect your favorite Apps with ' + BRAND_NAME,
    mobileDisplay: "Integrations",
    value: 'integrations',
    locator: "dropdown",
    mobileLocator: 'dropdown'
  },
  help: {
    display: <span className="header-dropdown-icon">
      <i className="fa fa-question-circle-o"></i>
      <span>Help</span></span>,
    redirectUrl: Constants.CLEAROUT_WEBSITE.HELP,
    title: 'Knowledge Center',
    mobileDisplay: "Help",
    value: 'help',
    isExternal: true,
    locator: "dropdown",
    mobileLocator: 'dropdown'
  },
  account: {
    display: <span className="header-dropdown-icon">
      <i className="fa fa-user-o"></i>
      <span>Account</span>
    </span>,
    redirectUrl: '/dashboard/account',
    title: 'Manage Profile, Billing and Purchase Credits',
    mobileDisplay: <i className="fa fa-user-o"></i>,
    value: 'profile',
    locator: 'dropdown',
    mobileLocator: 'header'
  },
  apps: {
    display: 'Apps',
    redirectUrl: '/dashboard/apps',
    title: 'Create/Edit App to generate API tokens',
    mobileDisplay: 'Apps',
    value: 'apps',
    locator: "header",
    mobileLocator: "dropdown"
  },
  analytics: {
    display: <span className="header-dropdown-icon">
      <i className="fa fa-area-chart"></i>
      <span>Analytics</span></span>,
    redirectUrl: '/dashboard/analytics',
    title: 'Your verification performance insights',
    mobileDisplay: 'Analytics',
    value: 'analytics',
    locator: "dropdown",
    mobileLocator: "dropdown"
  },
  pricing: {
    display: 'Pricing',
    redirectUrl: '/dashboard/account',
    title: 'Pricing',
    mobileDisplay: <i className="fa fa-user"></i>,
    value: 'pricing'
  },
  profile: {
    display: <span className="header-dropdown-icon">
      <i className="fa fa-user-o"></i>
      <span>Account</span>
    </span>,
    redirectUrl: '/dashboard/account',
    title: 'Manage Profile, Billing and Purchase Credits',
    mobileDisplay: 'Profile',
    value: 'profile',
    locator: 'dropdown',
    mobileLocator: 'dropdown'
  },
  billing: {
    display: 'Billing',
    redirectUrl: '/dashboard/account',
    title: 'Billing',
    mobileDisplay: <i className="fa fa-user"></i>,
    value: 'billing'
  },
  coupons: {
    display: 'Coupons',
    redirectUrl: '/dashboard/account',
    title: 'Billing',
    mobileDisplay: <i className="fa fa-tags"></i>,
    value: 'billing'
  },
  api: {
    display: 'API' + <i className="fa fa-external-link pl-3"></i>,
    redirectUrl: '/dashboard/account',
    title: 'API',
    mobileDisplay: <i className="fa fa-user"></i>,
    value: 'api'
  },
  my_activity: {
    display: <span className="header-dropdown-icon">
      <i className="fa fa-history"></i>
      <span>Activities</span></span>,
    redirectUrl: '/dashboard/activities',
    title: 'Get to know your credit usage and verification history',
    mobileDisplay: 'Activities',
    value: 'my_activity',
    showBadge: false,
    locator: 'dropdown',
    mobileLocator: 'dropdown'
  },
  email_finder: {
    display: "Email Finder",
    redirectUrl: '/dashboard/email-finder',
    title: 'Find email addresses in bulk or instant',
    mobileDisplay: <img className="list-png" src={require("../static/img/email_finder.png")} alt="Email Finder" />,
    value: 'email_finder',
    locator: 'header',
    showBadge: false,
    mobileLocator: 'header'
  },
  widget: {
    locator: 'header',
    mobileLocator: 'header'
  },
  upgrade: {
    locator: 'header',
    mobileLocator: 'dropdown'
  },
  admin: {
    locator: 'dropdown',
    mobileLocator: 'dropdown'
  },
  logout: {
    locator: 'dropdown',
    mobileLocator: 'dropdown'
  },
  prospect: {
    display: "Prospect",
    redirectUrl: '/dashboard/prospect',
    mobileDisplay: <ReactSVG src={require("../static/svg/prospect.svg")} className="list-svg prospect" />,
    title: 'Capture, Search, Enrich Prospects',
    value: 'prospect',
    locator: 'header',
    showBadge: false,
    mobileLocator: 'header'
  },

  settings: {
    display: <span className="header-dropdown-icon">
      <i className="fa fa-cog"></i>
      <span>Settings</span></span>,
    redirectUrl: '/dashboard/settings',
    title: SETTINGS_MENUS.includes('email_finder')
      ? 'Manage and customize your preferences for verification, notifications, and email finder'
      : 'Manage and customize your preferences for verification and notifications',
    mobileDisplay: <span>Settings</span>,
    value: 'settings',
    showBadge: false,
    locator: 'dropdown',
    mobileLocator: 'dropdown'
  },
  g2crowd: {
    locator: 'header',
    mobileLocator: 'dropdown'
  },
}
