export const CO_USER = 'userReducer'

export const APP_URL = APP_API_URL
export const API_VERSION = 'v2'

export const POPUP_WINDOW_WIDTH = "600"
export const POPUP_WINDOW_HEIGHT = "600"
export const MAX_CREDIT_BUY = 5000000
export const MIN_CREDIT_BUY = 3000
export const TIMER_LIMIT = 30
export const THOUSAND_MILLI_SECONDS = 1000

export const WELCOME_MODAL_REQUIRED_VALUE = 'yes'
export const ALERT_MODAL = 'modal_alert'
export const ALERT_MODAL_PRIORITY = 100
export const VERSION_MODAL = 'version_modal'
export const VERSION_MODAL_PRIORITY = 3
export const CREDIT_MODAL = 'credit_modal'
export const CREDIT_MODAL_PRIORITY = 2
export const WELCOME_MODAL = 'welcome_modal'
export const WELCOME_MODAL_PRIORITY = 1


export const CO_API = {
  Success: "success",
  Failed: "failed",
  Header: {
    'Content-Type': 'application/json'
  }
}

export const BRANDS = {
  clearout: 'clearout',
  pepipost: 'pepipost'
}

export const CLEAROUT_WEBSITE = {
  HOME: ENV_URL,
  PRIVACY_POLICY: `${ENV_URL}privacy-policy/`,
  TERMS_AND_CONDITIONS: `${ENV_URL}terms-condition/`,
  CONTACT_US: `${ENV_URL}contact-us/`,
  API_DOC: 'https://docs.clearout.io/',
  HELP: `${ENV_URL}/help/`,
  UNDERSTANDING_EMAIL_VERIFICATION_RESULT: `${ENV_URL}help/#understanding_email_verification_results`,
  UNDERSTANDING_EMAIL_VERIFICATION_CANCELLED_RESULT: `${ENV_URL}help/#understanding_email_verification_cancelled_results`,
  EMAIL_VERIFICATION_SETTINGS_HELP: `${ENV_URL}help/#email-veriifcation-settings`,
  TEAM_ACCOUNT: `${ENV_URL}team-account/`,
  HELP_GUARANTEED_DELIVERABILITY: `${ENV_URL}help/#email_deliverability_guarantee`,
  NOTIFICATIONS_SETTINGS_HELP: `${ENV_URL}help/#Notifications_On_Off`,
  PAYMENT_SETTINGS_HELP: `${ENV_URL}help/#payments`,
  EMAIL_FINDER_SETTINGS_HELP: `${ENV_URL}help/#Assured_Email_Finding`,
  CHROME_EXTENSION_DOWNLOAD_PAGE: `${ENV_URL}chrome-extension/`
}

export const RESULTS_STATUS_DISPLAY = {
  running: 'Running',
  completed: 'Completed',
  queued: 'Queued',
  failed: 'Failed',
}

export const LIST_STATUSES = {
  SUCCESS: 'success',
  FAILED: 'failed',
  CANCELLED: 'cancelled'
}

export const PAGINATION_FILTERS = [
  {
    "label": "10",
    "value": 10,
    "text": '10'
  }, {
    "label": "25",
    "value": 25,
    "text": '25'
  }, {
    "label": "50",
    "value": 50,
    "text": '50'
  }, {
    "label": "100",
    "value": 100,
    "text": '100'
  }
]

export const ADMIN_PAGINATION_FILTERS = [
  {
    "label": "50",
    "value": 50,
    "text": '50'
  }, {
    "label": "100",
    "value": 100,
    "text": '100'
  },
]

export const RANGES_LIMIT = {
  "daily": {
    value: 'daily',
    label: 'Daily'
  },
  'life_time': {
    value: 'life_time',
    label: 'Lifetime'
  }
}

export const DISPLAY_RANGES_LIMIT = {
  "daily": {
    value: 'daily',
    label: 'Daily'
  },
  'lifetime': {
    value: 'lifetime',
    label: 'Lifetime'
  }
}

export const USER_ROLES = {
  "admin": {
    value: 'admin',
    label: 'Manager'
  },
  'analyst': {
    value: 'analyst',
    label: 'Executive'
  },
}

export const ROLE_FILTER = {
  "all": {
    value: 'all',
    label: 'All'
  },
  "admin": {
    value: 'admin',
    label: 'Manager'
  },
  "owner": {
    value: 'owner',
    label: 'Owner'
  },
  'analyst': {
    value: 'analyst',
    label: 'Executive'
  },
}

export const CLEVERTAP_REGIONS = {
  "eu1": {
    value: 'eu1',
    label: 'EU1'
  },
  "in1": {
    value: 'in1',
    label: 'IN1'
  },
  "sg1": {
    value: 'sg1',
    label: 'SG1'
  },
  'us1': {
    value: 'us1',
    label: 'US1'
  },
  "aps3": {
    value: 'aps3',
    label: 'APS3'
  },
  'mec1': {
    value: 'mec1',
    label: 'MEC1'
  },
}

export const DISPLAY_USER_ROLES = {
  "admin": {
    value: 'admin',
    display_name: 'Manager',
    account_menus: ['profile', 'api', 'my_activity'],
    email_finder_menus: ['bulk_email_finder', 'single_email_finder'],
    setting_menus: ['settings', 'my_lists', 'email_finder', 'prospect'],
    prospect_menus: ['home', 'lists', 'people_profile', 'company_profile', 'people_search', 'company_search', 'saved_searches'],
    admin_menus: ['admin', 'my_lists', 'email_finder', 'prospect'],
    analytic_menus: ['overview', 'email_verifier', 'email_finder','prospect','team']
  },
  'analyst': {
    value: 'analyst',
    display_name: 'Executive',
    account_menus: ['profile', 'api', 'my_activity'],
    email_finder_menus: ['bulk_email_finder', 'single_email_finder'],
    setting_menus: ['settings', 'my_lists', 'email_finder', 'prospect'],
    prospect_menus: ['home', 'lists', 'people_profile', 'company_profile', 'people_search', 'company_search', 'saved_searches'],
    admin_menus: [],
    analytic_menus: ['overview', 'email_verifier', 'email_finder','prospect']
  },
  'owner': {
    value: 'owner',
    display_name: 'Owner',
    account_menus: ['profile', 'pricing', 'billing', 'my_activity', 'coupons', 'api'],
    email_finder_menus: ['bulk_email_finder', 'single_email_finder'],
    setting_menus: ['settings', 'my_lists', 'email_finder', 'prospect'],
    prospect_menus: ['home', 'lists', 'people_profile', 'company_profile', 'people_search', 'company_search', 'saved_searches'],
    admin_menus: ['admin', 'my_lists', 'email_finder', 'prospect'],
    analytic_menus: ['overview', 'email_verifier', 'email_finder','prospect','team']
  },
}

export const CUSTOM_HEADER_MENUS = ['g2crowd','upgrade', 'widget', 'admin', 'logout', 'prospects']

export const CHUNK_FILE_UPLOAD_ENDPOINT = API_VERSION + '/email_verify/bulk_upload'
export const EMAIL_FINDER_CHUNK_FILE_UPLOAD_ENDPOINT = API_VERSION + '/email_finder/bulk_upload'
export const INVOICE_DOWNLOAD_ENDPOINT = API_VERSION + '/invoice/download?'
export const DOWNLOAD_REQUEST_LINE_POLLING_API_ENDPOINT = API_VERSION + '/download/result/status?queue_id='

export const DATERANGE_PRESETS = {
  'date_range_presets': [
    'ps_today',
    'ps_yesterday',
    'ps_this_week_mon_today',
    'ps_last_7_days_including_today',
    'ps_last_week_mon_sun',
    'ps_this_month',
    'ps_last_month',
    'ps_this_year',
    'ps_last_30_days',
    'ps_last_year'
  ],
  date_range_presets_definitions: {
    ps_today: {
      display_name: 'Today',
      value: 'ps_today'
    },
    ps_yesterday: {
      display_name: 'Yesterday',
      value: 'ps_yesterday'
    },
    ps_last_7_days_including_today: {
      display_name: 'Last 7 days (Including Today)',
      value: 'ps_last_7_days_including_today'
    },
    ps_this_week_mon_today: {
      display_name: 'This week (Mon - Today)',
      value: 'ps_this_week_mon_today'
    },
    ps_last_week_mon_sun: {
      display_name: 'Last week (Mon - Sun)',
      value: 'ps_last_week_mon_sun'
    },
    ps_this_month: {
      display_name: 'This Month',
      value: 'ps_this_month'
    },
    ps_last_month: {
      display_name: 'Last Month',
      value: 'ps_last_month'
    },
    ps_this_year: {
      display_name: 'This Year',
      value: 'ps_this_year'
    },
    ps_last_24_hours: {
      display_name: 'Last 24 Hrs',
      value: 'ps_last_24_hours'
    },
    ps_last_30_days: {
      display_name: 'Last 30 Days',
      value: 'ps_last_30_days'
    },
    ps_last_year: {
      display_name: 'Last Year',
      value: 'ps_last_year'
    }
  }
}

export const IMAGE_CARD_BRANDS = ['visa', 'mastercard', 'Discover', 'amex', 'Diners Club', 'JCB']

//result table display constants
export const ANALYSIS_TABLE_DISPLAY_ORDER = ['duplicate', 'syntax_error', 'disposable', 'free_account', 'role_account']
export const USERS_PERFORMANCE_STATS_TABLE_DISPLAY_ORDER = ['total', 'valid', 'invalid', 'unknown', 'catch_all', 'duplicate']
export const TOP_RESULTS_TABLE_DISPLAY_ORDER = ['total', 'valid', 'invalid', 'catch_all', 'unknown',]
export const DELIVERABILITY_TABLE_DISPLAY_ORDER = ['total', 'billable']
export const HISTORY_TABLE_DISPLAY_STATS = ['deliverability_score']

// result table display metrics based on actions(sync, analyse, verify)
export const SYNC_AND_EXPORT_CARD_RESULTS = ['total']
export const ANALYSE_CARD_RESULTS = ['total', 'duplicate', 'syntax_error', 'disposable', 'free_account', 'role_account']
export const ALL_RESULT_METRICS = ['total', 'valid', 'invalid', 'catch_all', 'unknown', 'duplicate', 'syntax_error', 'disposable', 'free_account', 'role_account']

export const PIECHART_RESULT_ORDER = ['valid', 'invalid', 'catch_all', 'unknown']
export const SUMMARY_TABLE_ORDER = ['valid', 'invalid', 'catch_all', 'unknown', 'total']


export const RESULTS = {
  total: {
    display_name: 'Total',
    type: 'value'
  },
  valid: {
    display_name: 'Valid',
    type: 'percentage'
  },
  invalid: {
    display_name: 'Invalid',
    type: 'percentage'
  },
  catch_all: {
    display_name: 'Catch All',
    type: 'percentage'
  },
  unknown: {
    display_name: 'Unknown',
    type: 'percentage'
  },
  duplicate: {
    display_name: 'Duplicate',
    type: 'percentage'
  },
  disposable: {
    display_name: 'Disposable',
    type: 'percentage'
  },
  role_account: {
    display_name: 'Role Account',
    type: 'percentage'
  },
  free_account: {
    display_name: 'Free Account',
    type: 'percentage'
  },
  profile: {
    display_name: 'Profile Account',
    type: 'percentage'
  },
  billable: {
    display_name: 'Billable',
    type: 'value'
  },
  deliverability_score: {
    display_name: 'Deliverability Score',
    type: 'value'
  },
  syntax_error: {
    display_name: 'Syntax Error',
    type: 'percentage'
  },
  billable_credits: {
    display_name: 'Credits Used (Billable)',
    type: 'value'
  },
  safe_to_send: {
    display_name: 'Safe To Send',
    type: 'percentage'
  }
}

export const SERVICE_BASED_REPORT_FIELDS = {
  verify : {
    total: {
      display_name: 'Total',
      type: 'value'
    },
    valid: {
      display_name: 'Valid',
      type: 'percentage'
    },
    invalid: {
      display_name: 'Invalid',
      type: 'percentage'
    },
    catch_all: {
      display_name: 'Catch All',
      type: 'percentage'
    },
    unknown: {
      display_name: 'Unknown',
      type: 'percentage'
    },
    duplicate: {
      display_name: 'Duplicate',
      type: 'percentage'
    },
    disposable: {
      display_name: 'Disposable',
      type: 'percentage'
    },
    role_account: {
      display_name: 'Role Account',
      type: 'percentage'
    },
    free_account: {
      display_name: 'Free Account',
      type: 'percentage'
    },
    profile: {
      display_name: 'Profile Account',
      type: 'percentage'
    },
    billable: {
      display_name: 'Billable',
      type: 'value'
    },
    deliverability_score: {
      display_name: 'Deliverability Score',
      type: 'value'
    },
    syntax_error: {
      display_name: 'Syntax Error',
      type: 'percentage'
    },
    billable_credits: {
      display_name: 'Credits Used (Billable)',
      type: 'value'
    },
    safe_to_send: {
      display_name: 'Safe To Send',
      type: 'percentage'
    }
  },
  finder : {
    total: {
      display_name: 'Total Request',
      type: 'value'
    },
    found: {
      display_name: 'Email Found',
      type: 'value'
    },
    not_found: {
      display_name: 'Email Not Found',
      type: 'value'
    },
    business: {
      display_name: 'Business',
      type: 'value'
    },
    role: {
      display_name: 'Role',
      type: 'value'
    },
    confidence_score: {
      display_name: 'Confidence Score',
      type: 'value'
    },
    billable: {
      display_name: 'Billable',
      type: 'value'
    }
  },
  prospect : {
    total_billable_email_contact: {
      display_name: 'Email Contacts',
      type: 'value'
    },
    total_billable_phone_contact:{
      display_name: 'Phone Contacts',
      type: 'value'
    },
    total_billable_lead:{
      display_name: 'Total Prospects',
      type: 'value'
    },
    total_credits_charged:{
      display_name: 'Total Credits',
      type: 'value'
    },
    email: {
      display_name: 'Email Lead',
      type: 'value'
    },
    phone: {
      display_name: 'Phone Lead',
      type: 'value'
    },
    billable: {
      display_name: 'Billable',
      type: 'value'
    }
  },
  overall : {
    ev_billable: {
      display_name: 'Email Verifier',
      type: 'value'
    },
    ef_billable: {
      display_name: 'Email Finder',
      type: 'value'
    },
    prospect_billable: {
      display_name: 'Prospect',
      type: 'value'
    }
  }
}

export const PERFORMANCE_STATS = {
  total: {
    display_name: 'Total',
    type: 'value'
  },
  valid: {
    display_name: 'Valid',
    type: 'value'
  },
  invalid: {
    display_name: 'Invalid',
    type: 'value'
  },
  catch_all: {
    display_name: 'Catch All',
    type: 'value'
  },
  unknown: {
    display_name: 'Unknown',
    type: 'value'
  },
  duplicate: {
    display_name: 'Duplicate',
    type: 'value'
  },
}

export const AVG_EMAIL_SENDING_COST = 0.015 // according to mailchimp

export const GRAPH_COLORS = ['#c6e377', '#f16f6f', '#75cac3', '#c0c0c0', '#970690']

export const GRAPH_AREA_COLORS = ['rgba(199, 227, 120, 0.20)', 'rgba(241, 111, 111, 0.20)', 'rgba(118, 203, 196, 0.20)', 'rgba(191, 191, 191, 0.20)', 'rgba(235, 193, 233, 0.2)']

//Version check and page reload related
export const HARD_RELOAD = 'hard_reload'
export const EMPTY_DATA_AND_HARD_RELOAD = 'empty_data_and_hard_reload'

export const DELIVERABILITY_SCORE_NOTE = "A score to measure your reach to the client's inbox. It is calculated on the basis of multiple factors like the historical sender score, no. of times domain has been blacklisted, role-based, etc."

export const BILLABLE_EMAIL_NOTE = "All valid, invalid and catch-all email addresses are considered billable. Duplicate and unknown/undetermined emails are not billable and the credits for the same will not be deducted."

export const CREDITS_USAGE_NOTE = 'For Email Verifier, 1 Credit charged per Billable Email <br/>For Email Finder, 4 Credits charged per non Role-based Email Found / 2 Credits charged per Role-based Email Found'

export const MIN_CREDITS_LINK_VALUE = 500
export const FREE_CREDITS = 100

export const MAX_EMAIL_ADDRESSES_ALLOWED_QUICK_VALIDATION = 10

export const PRICING_MAX_FOR_MILLION = 1000001
export const PRICING_MAX_VALUE = 10000000
export const LIST_LOAD_TIME = 30000
export const USERNAME_CHAR_LIMIT = 30
export const PASSWORD_CHAR_LIMIT = 30
export const ORGANIZATION_NAME_CHAR_LIMIT = 50
export const VERIFIED_TYPES = {
  'verified': {
    value: 'verified',
    label: 'Verified'
  },
  'non_verified': {
    value: 'non_verified',
    label: 'Non-Verified'
  },
  'in_progress': {
    value: 'in_progress',
    label: 'In Progress'
  },
  'cancelled': {
    value: 'cancelled',
    label: 'Cancelled'
  },
}
export const PROCESSED_TYPES = {
  'processed': {
    value: 'processed',
    label: 'Processed'
  },
  'not_processed': {
    value: 'not_processed',
    label: 'Not-Processed'
  },
  'in_progress': {
    value: 'in_progress',
    label: 'In Progress'
  },
  'cancelled': {
    value: 'cancelled',
    label: 'Cancelled'
  },
}
export const LIST_TYPES = {
  upload: {
    value: 'upload',
    label: 'Upload'
  },
  apollo: {
    value: 'apollo',
    label: 'Apollo'
  },
  mailchimp: {
    value: 'mailchimp',
    label: 'Mailchimp'
  },
  active_campaign: {
    value: 'active_campaign',
    label: 'ActiveCampaign'
  },
  moosend: {
    value: 'moosend',
    label: 'Moosend'
  },
  hubspot: {
    value: 'hubspot',
    label: 'HubSpot'
  },
  mailerlite: {
    value: 'mailerlite',
    label: 'MailerLite'
  },
  sendgrid: {
    value: 'sendgrid',
    label: 'Sendgrid'
  },
  automizy: {
    value: 'automizy',
    label: 'Automizy'
  },
  clevertap: {
    value: 'clevertap',
    label: 'CleverTap'
  },
  leads: {
    value: 'leads',
    label: 'Leads'
  },
  all: {
    label: 'All',
    value: 'all'
  }
}

export const LIST_ACTIONS = {
  sync: {
    value: 'sync',
    display: 'Sync',
    progress: 'Syncing',
    queued: ' Queued',
    history: 'synced'
  },
  verify: {
    value: 'verify',
    display: 'Verify',
    progress: 'Verifying',
    queued: ' Queued',
    history: 'verified'
  },
  analyse: {
    value: 'analyse',
    display: 'Analyse',
    progress: 'Analyzing',
    queued: ' Queued',
    history: 'analysed'
  },
  export: {
    value: 'export',
    display: 'Export',
    progress: 'Exporting',
    queued: ' Queued',
    history: 'exported'
  },
  failed: {
    value: 'failed',
    display: 'Failed',
    progress: 'Failing',
    queued: ' Queued',
    history: 'failed'
  },
  find_emails: {
    value: 'email_finder',
    display: 'Finding Emails',
    progress: 'Finding Emails',
    queued: ' Queued',
    history: 'Email Found'
  },
  enrich: {
    value: 'enrich',
    display: 'Enriching Leads',
    progress: 'Enriching Leads',
    queued: ' Queued',
    history: 'Email Found'
  }
}

export const FILE_TYPE = {
  csv: 'CSV',
  xlsx: 'XLSX'
}

export const QUICK_VALIDATION_TIMEOUT = 180000
export const UNSUBSCRIBE_OPTIONS = ['invalid', 'catch_all', 'unknown']
export const APPEND_OPTIONS = ['status', 'reason', 'safe_to_send', 'suggested_email_address', 'disposable', 'free_account', 'role_account', 'mx_record', 'smtp_provider', 'verified_on']
export const DOWNLOAD_FILE_APPEND_COLUMNS = ['status', 'reason', 'score', 'suggested_email_address', 'greylist_count', 'disposable', 'free_account', 'role_account', 'account', 'domain', 'verified_on', 'time_taken']
export const DOWNLOAD_VALIDATION_FILTER = ['valid', 'invalid', 'catch_all', 'unknown']
export const HELP_TEXT_STATUS = ['Billable', 'Deliverability Score', 'Catch All', 'Unknown', 'Disposable', 'Free Account', 'Role Account']

export const HELP_TEXT_STATUS_DEFS = {
  billable: {
    defs: 'Any Valid, Invalid or Catch-all email address is considered as a billable email. Duplicate, Unknown where ' + BRAND_NAME + ' unable to determine the verify status will not be counted as billable and credits would not used',
  },
  deliverability_score: {
    defs: 'Each email address is assigned a deliverability score value range from 0-1 to indicate the probability of email message getting delivered to that email address. Determining deliverability score involves multiple factors that is unique to  ' + BRAND_NAME + '  system. The overall deliverability score at list level is an average deliverability score of all valid email addresses in percentage form',
  },
  catch_all: {
    defs: 'A catch-all email address is an account that receives emails sent to any incorrect email addresses of the domain. When configured, emails sent to any email address associated with the domain should not bounce, but in some cases, it may reject later. That is why the safe to send flag is marked as risky',
  },
  unknown: {
    defs: 'If ' + BRAND_NAME + ' was unable to connect or get response from the recipient\'s mail server those email addresses are categorized as Unknown. BTW, unknown status won\'t deduct any credits',
  },
  disposable: {
    defs: 'Email addresses that are created for temporary purpose or live for short span of time are categorized under disposable',
  },
  free_account: {
    defs: 'Email addresses that are from free mail service provider such as Gmail, Yahoo! are categorized under free account',
  },
  role_account: {
    defs: 'Email addresses that are typically associated with a team (info, support, sales, etc.) instead of a person will be categorized under role account',
  },
  safe_to_send: {
    defs: 'The result will only include the email addresses that are guaranteed to be delivered to the mailbox of the recipient, i.e. no bounce back. Sending emails to these email addresses is completely safe as long as the email is sent anytime before 24 hours from the verified time. The downloaded result will also contain ' + BRAND_NAME + ' standard columns appended to the original columns.',
  },
  duplicate: {
    defs: 'only the first occurrence of the duplicate email address row will be considered in the result file.'
  }
}

export const EMAIL_FINDER_HELP_TEXT_STATUS_DEFS = {
  billable: {
    defs: "Any Email Found will be considered as a billable. Duplicate, Not Found will not be counted as part of billable, so the credits won't be charged for those statuses",
  },
  confidence_level: {
    defs: "An email deliverability scale to indicate how good the discovered email addresses of the list and how good the list can be used for cold email outreach campaign by protecting the domain reputation, increasing the open rate percentage"
  }
}



//applied with status filter
export const VALIDATION_FILTERS = {
  valid: {
    value: 'valid',
    name: 'Valid'
  },
  invalid: {
    value: 'invalid',
    name: 'Invalid'
  },
  catch_all: {
    value: 'catch_all',
    name: 'Catch All'
  },
  unknown: {
    value: 'unknown',
    name: 'Unknown'
  },
}
export const APPEND_COLUMNS = {
  status: {
    value: 'status',
    name: 'Status'
  },
  reason: {
    value: 'reason',
    name: 'Reason'
  },
  // score: {
  //   value: 'score',
  //   name: 'Score'
  // },
  greylist_count: {
    value: 'greylist_count',
    name: 'Greylist Count'
  },
  verified_on: {
    value: 'verified_on',
    name: 'Verified On'
  },
  suggested_email_address: {
    value: 'suggested_email_address',
    name: 'Suggested Email Address'
  },
  disposable: {
    value: 'disposable',
    name: 'Disposable'
  },
  role_account: {
    value: 'role_account',
    name: 'Role Account'
  },
  free_account: {
    value: 'free_account',
    name: 'Free Account'
  },
  account: {
    value: 'account',
    name: 'Account'
  },
  domain: {
    value: 'domain',
    name: 'Domain'
  },
  time_taken: {
    value: 'time_taken',
    name: 'Time Taken'
  },
  safe_to_send: {
    value: 'safe_to_send',
    name: 'Safe To Send'
  },
  mx_record: {
    value: 'mx_record',
    name: 'MX Record'
  },
  smtp_provider: {
    value: 'smtp_provider',
    name: 'SMTP Provider'
  }
}
export const UNSUBSCRIBE_COLUMNS = {
  catch_all: {
    value: 'catch_all',
    name: 'Catch All'
  },
  unknown: {
    value: 'unknown',
    name: 'Unknown'
  },
  invalid: {
    name: 'Invalid',
    value: 'invalid'
  },
}
// export const STATUS_FILTER_HELP_TEXT="By applying status filter will generate the result file with the email addresses that match the chosen status values "
export const STATUS_FILTER_HELP_TEXT = "Apply this filter to include verified email addresses with a particular status from - Valid, Invalid, Unknown and Catch all."

// export const LEADS_INFO = "Lead Finder is a great tool to find prospective clients for your B2B business. With the help of this tool you can extract a list of prospects that you can approach. The Lead finder provides you with the prospect's name, company address, email address and phone number."
// export const LEADS_EXPIRYDATE = "2020-05-13"
//export const APPEND_HELP_TEXT = "All columns selected for append will be appended with '" + BRAND_NAME + " Verification' in result file. Eg: Status will be '" + BRAND_NAME + " Verification Status'"
export const APPEND_HELP_TEXT = "Selected columns will be added with column name prefix '" + BRAND_NAME + "' as part of the email record in the list"

export const UNSUBSCRIBE_HELP_TEXT = "Selecting Unsubscribe as part of export will unsubscribe those contacts in the choosen list whose email address matches with the selected validated result status option email address"
export const DAILY_VERIFY_LIMIT_TEXT = 'Indicates how many verify request can be made per day.'

export const ALLOWED_DAILY_LIMIT = 'Metric to know how many verify request are allowed per day.'

export const AVAILABLE_DAILY_LIMIT = 'Metric to know how many verify request are remaining per day.'

export const INTEGRATION_PLATFORMS = ['mailchimp', 'active_campaign', 'clevertap', 'moosend', 'hubspot', 'mailerlite', 'sendgrid', 'automizy', 'apollo']

export const ACCOUNT_NAME = "Friendly unique name to identify this account"

export const ACTIVE_CAMPAIGN_URL = "Login to ActiveCampaign and navigate to settings -> Developer -> URL"

export const ACTIVE_CAMPAIGN_KEY = "Login to ActiveCampaign and navigate to settings -> Developer -> Key"

export const CLEVERTAP_ACCOUNTID = "Login to CleverTap and navigate to settings -> Project Id"

export const CLEVERTAP_PASSCODE = "Login to CleverTap and navigate to settings -> Passcode"

export const APOLLO_KEY = "Login to Apollo and navigate to Settings -> Integrations -> API Settings"

export const PRICING_RESTRICT_ROLES = ['analyst', 'admin']
export const ADMIN_RESTRICT_ROLES = ['owner', 'admin']
export const OWNER_RESTRICT_ROLES = ['owner']
export const OWNER_ACCESS = ['pricing', 'billing']
export const MOOSEND_API_KEY = "Login to Moosend and navigate to Settings -> Api Key"
export const SENDGRID_API_KEY = "Login to Sendgrid and navigate to Settings -> Api Key"
export const AUTOMIZY_API_KEY = "Login to Automizy and navigate to Settings -> Api Token"
export const MAILERLITE_API_KEY = "Login to MailerLite and navigate to Account -> Integrations -> Developer API"

export const UNLIMITED_CREDIT_HELPTEXT = "No any specific credit based usage limit"
export const UNLIMITED_ALLOWED_DAILY_LIMIT_HELPTEXT = "No any specific daily usage limit"
export const UNLIMITED_REMAINING_DAILY_LIMIT_HELPTEXT = "No any specific daily usage limit for today"
export const ALLOWED_DAILY_LIMIT_HELPTEXT = "Any update in allowed daily limit value will be applied start of following day"
export const REMAINING_DAILY_LIMIT_HELPTEXT = "Any update in remaining daily limit will be applied immediately and will be reset to allowed daily limit on start of following day"
export const DOWNLOAD_MODAL_HEADING = "Email addresses with " + BRAND_NAME + " standard columns "
export const DOWNLOAD_ORIGINAL_FILE_OPTION = "Original file with " + BRAND_NAME + " standard columns"
export const DOWNLOAD_ORIGINAL_FILE_OPTION_WITH_DUPLICATE = "Original file with " + BRAND_NAME + " standard columns (include duplicates)"
export const CURRENCY = {
  USD: {
    symbol: '$',
    name: 'USD',
    locale: 'en-US'
  },
  INR: {
    symbol: '₹',
    name: 'INR',
    locale: 'en-IN'
  }
}

export const COUPON_TYPE = {
  discount: 'discount',
  standard: 'standard'
}

export const DELIVERABLES = 'The result file will include only the valid email addresses appended to your original list. Sending emails to these email addresses are completely safe.'
export const DELIVERABLES_GUARANTEED = 'The result will only include the email addresses that are guaranteed to be delivered to the mailbox of the recipient, i.e. no bounce back. Sending emails to these email addresses is completely safe as long as the email is sent anytime before 24 hours from the verified time. The downloaded result will also contain ' + BRAND_NAME + ' standard columns appended to the original columns.'

export const NON_DELIVERABLES = "The result file will include only the invalid email addresses appended to your original list. An email address will be marked 'invalid' for many reasons. Avoid sending emails to these email addresses."
export const NON_DELIVERABLES_SAFE_TO_SEND = 'The result will include the email addresses that will certainly bounce, so it is highly recommended not to send any emails to such addresses and unsubscribe them from the mailing list'

export const DELIVERABLES_WITH_RISK = 'The result file will include email addresses that are guaranteed to be delivered along with the email addresses that are determined as risky. The email address of this result file are safe to be used when bounce rate is strictly below 5% or running dedicated mail server with out dependence on external Email Service Provider(ESP).'

export const SIGNUP_USER_SOURCES = [
  {
    label: 'Google Search',
    value: 'Google Search',
    icon: "fa fa-google"
  },
  {
    label: 'Bing Search',
    value: 'Bing Search',
    icon: "fa fa-search"
  },
  {
    label: 'Facebook',
    value: 'Facebook',
    icon: "fa fa-facebook-square"
  },
  {
    label: 'Twitter',
    value: 'Twitter',
    icon: "fa fa-twitter-square"
  },
  {
    label: 'Quora',
    value: 'Quora',
    icon: "fa fa-quora"
  },
  {
    label: 'LinkedIn',
    value: 'LinkedIn',
    icon: "fa fa-linkedin-square"
  },
  {
    label: 'Youtube',
    value: 'Youtube',
    icon: "fa fa-youtube-square"
  },
  {
    label: 'Blog',
    value: 'Blog',
    icon: "fa fa-rss-square"
  },
  {
    label: 'Online Ads',
    value: 'Online Ads',
    icon: "fa fa-laptop"
  },
  {
    label: 'Recommendation from Friends/Family',
    value: 'Recommendation from Friends/Family',
    icon: "fa fa-users"
  },
  {
    label: 'Recommendation from Work, Co-Workers or Customers',
    value: 'Recommendation from Work, Co-Workers or Customers',
    icon: "fa fa-briefcase"
  },
  {
    label: 'Team Invite',
    value: 'Team Invite',
    icon: "fa fa-envelope"
  },
  {
    label: 'Others : (Please Specify)',
    value: 'Others : (Please Specify)',
    icon: ""
  }
]

export const CANCEL_REASONS = [
  {
    label: "I don’t understand how to use your product",
    value: "I don’t understand how to use your product"
  },
  {
    label: "It’s too expensive",
    value: "It’s too expensive"
  },
  {
    label: "I found another product that I like better",
    value: "I found another product that I like better"
  },
  {
    label: "I don’t use it enough",
    value: "I don’t use it enough"
  },
  {
    label: "Some features I need are missing",
    value: "Some features I need are missing"
  },
  {
    label: "Not realizing the value proposition",
    value: "Not realizing the value proposition"
  },
  {
    label: "Poor customer support",
    value: "Poor customer support"
  }
  ,
  {
    label: 'Others : (Please Specify)',
    value: 'Others : (Please Specify)',
  }
]

export const SUBS_PRICING_DISCOUNT_TEXT = 'Pay Monthly / Annual - 20% less than One Time'
export const SUBS_PRICING_MONTHLY_DISCOUNT_TEXT = 'Get 20% discount for the price when you pay annually.'
export const HEADER_SUBS_PRICING_DISCOUNT_TEXT = 'Pay 20% less than One Time (Pay-as-you-go), Unused credits never expire'

export const UTM_PARAMS = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_network', 'utm_device', 'utm_adgroup', 'utm_creative', 'utm_placement', 'utm_keyword', 'utm_matchtype', 'utm_ga_loc_physical_ms', 'adposition']

export const USER_SOURCE = {
  BULK_VERIFY: 'bulk_verify',
  INSTANT_VERIFY: 'instant_verify',
  BULK_FINDER: 'bulk_finder',
  INSTANT_FINDER: 'instant_finder',
  ENRICH_LIST: 'enrich_list',
  ENRICH_LEAD: 'enrich_lead',
  ADD_LEAD: 'add_lead'
}

export const API_RATE_LIMIT_TIME = {
  "minute": {
    value: 'minute',
    label: 'Minute'
  },
  'hour': {
    value: 'hour',
    label: 'Hour'
  },
  'day': {
    value: 'day',
    label: 'Day'
  },
}

export const API_RATE_LIMIT_SOURCE = {
  "ip": {
    value: 'ip',
    label: 'per IP'
  },
  'globally': {
    value: 'globally',
    label: 'Globally'
  }
}

export const APP_STATUS = {
  'all_apps': {
    value: 'all_apps',
    label: 'All'
  },
  'active': {
    value: 'active',
    label: 'Active'
  },
  'inactive': {
    value: 'inactive',
    label: 'InActive'
  },
}

export const USER_STATUS = {
  'all_apps': {
    value: 'all_apps',
    label: 'All'
  },
  'all': {
    value: 'all',
    label: 'All'
  },
  'new': {
    value: 'new',
    label: 'Yet to Activate'
  },
  'active': {
    value: 'active',
    label: 'Active'
  },
  'inactive': {
    value: 'inactive',
    label: 'InActive'
  },
  'removed': {
    value: 'removed',
    label: 'Removed'
  }
}

export const APP_TYPES = {
  'server': {
    value: 'server',
    label: 'Server'
  },
  'client': {
    value: 'client',
    label: 'Client'
  }
}

export const CLEVERTAP_LISTS_TYPES = {
  'users_with_an_email_address': 'Users with an Email Address',
  'users_reachable_on_email': 'Users Reachable On Email',
  'users_who_have_app_launched_in_the_last_7_days': 'Users who have App Launched In the Last 7 Days',
  'users_who_have_app_launched_in_the_last_30_days': 'Users who have App Launched In the Last 30 Days',
}

export const EMAIL_VERIFY_MODES = {
  'highest_accuracy': 'Highest Accuracy',
  'fastest_turnaround': 'Fastest Turnaround',
  'FASTEST_TURNAROUND': 'fastest_turnaround',
  'HIGHEST_ACCURACY': 'highest_accuracy'
}

export const MAX_BULK_UPLOAD_FILE_NAME_LENGTH = 210

export const APP_FEATURE = {
  EMAIL_VERIFY: 'email_verify',
  EMAIL_FINDER: 'email_finder',
  PROSPECT: 'prospect'
}

// Email finder date filters
export const EF_DATE_FILTERS = [
  'ps_today',
  'ps_yesterday',
  'ps_this_week_mon_today',
  'ps_last_7_days_including_today',
  'ps_last_week_mon_sun',
  'ps_this_month',
  'ps_last_30_days',
]

export const PAYMENT_GATEWAYS = {
  PAYPAL: 'paypal',
  STRIPE: 'stripe'
}

export const PAYMENT_TYPE = {
  PAY_AS_YOU_GO: 'Pay As You Go',
  SUBSCRIPTION: 'Subscription'
}

export const DETAILS_FORM = {
  CARD: 'card',
  ADDRESS: 'address',
  ADDRESS_AND_CARD: 'address_and_card'
}

export const SERVICE_TYPES = {
  'email_verify': {
    value: 'email_verify',
    label: 'Email Verify'
  },
  'email_finder': {
    value: 'email_finder',
    label: 'Email Finder'
  }
}

export const MEMBER_STATUS = {
  Removed: "removed",
  Inactive: "inactive",
  Active: "active"
}

export const MEMBER_ROLE = {
  Admin: "admin",
  Owner: "owner",
  Analyst: "analyst"
}

export const PICK_MEMBER = {
  MEMBER_STATUS_TYPE_NOT_REMOVED_OWNER: "member is not owner and not removed"
}

export const DISPLAY_MESSAGE = {
  MEMBER_ADDED_SUCCESS: "Member added successfully",
  MEMBER_UPDATE_SUCCESS: "Member updated successfully",
  OWNER_CANNOT_REMOVED: "Owner cannot be removed",
  ENTER_REQUIRED_SEAT_COUNT: "Please enter the required seat count",
  ENTER_REASON: "Please enter a reason",
  MEMBER_REMOVED_FROM_LIST_SUCCESS: "Member removed from your list successfully",
  MEMBER_REMOVED_SUCCESS: " has been removed from your list successfully",
  MEMBERS_REMOVED_SUCCESS: " Member(s) have been removed from your list successfully",
  OWNER_OR_REMOVED_MEMBER_CANT_REMOVE: "Member(s) cannot be removed ( either member might be owner or already been removed )",
  MEMBER_SEAT_RANNGE_ALERT: "Please select the seat range from 1 to 50"
}
export const ENABLE_AUTO_PILOT_FOR_USERS = [
  '60b87a73f4c4774e932d7e39', // jeff eu
  '6196aa8ab6232c05bb92882b', // dev (auto@start.idk)
  '5f488361300d6f3ebc1a2dbd', // GP eu
  '5f464142300d6f3ebc1a2c63', // prashanth eu
  '5f97af545a39801881c51721', // mathi eu
  '607fbf6fc03a314e8bd36697', // adi co
  '5c6e952feff3234f464b2ca7', // gnanaprakash.rathinam@gmail.com co
  '5c681f71cc56176d10d78f5b', // gp@socialfrontier.com co
  '5c7e2bcc678aa863b8ae1c38', // prashanth@socialfrontier.com co
  '5c6e90caeff3234f464b2c7c', // mathivanan@socialfrontier.com co
  '615efa8b86db8d2e3b95bce0', // pradeep local,
  '63f640348d7fb1ef0488afa8', // mathi test - mathivanan@socialfrontier.com
  '63d0c76a52b533b51ea128a8', // deyara test - deyara@socialfrontier.com
  '63d0c72552b533b51ea12868', // ausha test - anushsaran1502@gmail.com
]

export const POPUP_WINDOW_BROWSER_ENABLE_LINKS = [
  {
    browser: ['chrome'],
    os: ['ubuntu', 'linux', 'mac os', 'windows'],
    type: ['browser'],
    link: 'https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop'
  },
  {
    browser: ['chrome', 'android browser'],
    os: ['android'],
    type: ['mobile', 'tablet'],
    link: 'https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DAndroid&oco=1'
  },
  {
    browser: ['chrome'],
    os: ['ios'],
    type: ['mobile', 'tablet'],
    link: 'https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DiOS&oco=1'
  },
  {
    browser: ['firefox'],
    os: ['ubuntu', 'linux', 'mac os', 'windows'],
    type: ['browser'],
    link: 'https://support.mozilla.org/en-US/kb/pop-blocker-settings-exceptions-troubleshooting'
  },
  {
    browser: ['safari'],
    os: ['mac os'],
    type: ['browser'],
    link: 'https://support.apple.com/en-gb/guide/safari/sfri40696/mac'
  },
  {
    browser: ['mobile safari', 'safari'],
    os: ['ios'],
    type: ['tablet'],
    link: 'https://support.apple.com/en-in/guide/ipad/ipad0669fc3c/ipados'
  },
  {
    browser: ['mobile safari'],
    os: ['ios'],
    type: ['mobile'],
    link: 'https://support.apple.com/en-gb/guide/iphone/iphdc30e3b86/ios#:~:text=Go%20to%20Settings%20%3E%20Safari%2C%20then,turn%20on%20Block%20Pop%2Dups.'
  },
  {
    browser: ['ie'],
    os: ['windows'],
    type: ['browser'],
    link: 'https://support.microsoft.com/en-us/windows/change-security-and-privacy-settings-for-internet-explorer-11-9528b011-664c-b771-d757-43a2b78b2afe'
  },
  {
    browser: ['ie'],
    os: ['windows', 'windows phone'],
    type: ['mobile', 'tablet'],
    link: 'https://support.microsoft.com/en-us/windows/change-security-and-privacy-settings-for-internet-explorer-11-9528b011-664c-b771-d757-43a2b78b2afe'
  },
  {
    browser: ['edge'],
    os: ['windows'],
    type: ['browser'],
    link: 'https://support.microsoft.com/en-us/microsoft-edge/block-pop-ups-in-microsoft-edge-1d8ba4f8-f385-9a0b-e944-aa47339b6bb5'
  },
  {
    browser: ['edge'],
    os: ['windows'],
    type: ['mobile', 'tablet'],
    link: 'https://support.microsoft.com/en-us/microsoft-edge/block-pop-ups-in-microsoft-edge-1d8ba4f8-f385-9a0b-e944-aa47339b6bb5'
  }
]

export const SUBHEADER_BUTTONS = {
  QUICK_VALIDATION: "quick_validation",
  QUICK_FINDER: "quick_finder",
  ADD_LIST: "add_list",
  BUY_CREDITS: "buy_credits",
  DOMAIN_SETTINGS: "wb_domain_settings",
  SETTINGS: "settings",
  CREATE_LEAD_LIST: "create_lead_list",
  ADD_PROSPECT: "add_prospect"
}

export const USER_SETTINGS_DESCRIPTION = {
  EMAIL_VERIFIER: {
    HEADER: 'Use this space to set how email lists need to be verified either for accuracy or performance, as well as to categorize emails and domains depending on certain attributes'
  },
  NOTIFICATIONS: {
    HEADER: 'Use this space to manage how would you like to receive notifications on completion of email verification and low credit alerts'
  },
  PAYMENTS: {
    HEADER: 'Use this space to manage payment methods'
  },
  EMAIL_FINDER: {
    HEADER: 'Use this space to define how the email finder should handle the email discovery process'
  }
}

export const seatCountOption = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' }
]

export const SIGNUP_USER_INDUSTRY = [
  {
    label: 'Education / E-learning',
    value: 'Education / E-learning',
  },
  {
    label: 'Email Service Provider',
    value: 'Email Service Provider',
  },
  {
    label: 'Entertainment',
    value: 'Entertainment',
  },
  {
    label: 'Financial Services',
    value: 'Financial Services',
  },
  {
    label: 'Gaming / Gambling Casinos',
    value: 'Gaming / Gambling Casinos',
  },
  {
    label: 'Government',
    value: 'Government',
  },
  {
    label: 'Hospital / Healthcare',
    value: 'Hospital / Healthcare',
  },
  {
    label: 'Hospitality / Travel',
    value: 'Hospitality / Travel',
  },
  {
    label: 'Information / Data Services',
    value: 'Information / Data Services',
  },
  {
    label: 'Marketing / Advertising',
    value: 'Marketing / Advertising',
  },
  {
    label: 'Media',
    value: 'Media',
  },
  {
    label: 'Real estate / Mortgage',
    value: 'Real estate / Mortgage',
  },
  {
    label: 'Retail',
    value: 'Retail',
  },
  {
    label: 'Software / Hardware',
    value: 'Software / Hardware',
  },
  {
    label: 'Technology',
    value: 'Technology',
  },
  {
    label: 'Telecommunication / Wireless',
    value: 'Telecommunication / Wireless',
  },
  {
    label: 'Transportation',
    value: 'Transportation',
  },
  {
    label: 'Others : (Please Specify)',
    value: 'Others : (Please Specify)',
  }
]

export const SIGNUP_USER_JOB_TITLE = [
  {
    label: 'CEO / President / Owner / General Manager / Partner',
    value: 'CEO / President / Owner / General Manager / Partner',
  },
  {
    label: 'Vice President / Assistant Vice President',
    value: 'Vice President / Assistant Vice President',
  },
  {
    label: 'CFO / Treasurer / Controller',
    value: 'CFO / Treasurer / Controller',
  },
  {
    label: 'Director / Assistant Director / Department Head',
    value: 'Director / Assistant Director / Department Head',
  },
  {
    label: 'Manager / Assistant Manager',
    value: 'Manager / Assistant Manager',
  },
  {
    label: 'Professional (salaried)',
    value: 'Professional (salaried)',
  },
  {
    label: 'Technical (hourly)',
    value: 'Technical (hourly)',
  },
  {
    label: 'Small Business Owner',
    value: 'Small Business Owner',
  },
  {
    label: 'Doctor / Physician',
    value: 'Doctor / Physician',
  },
  {
    label: 'Developer / Programmer',
    value: 'Developer / Programmer',
  },
  {
    label: 'Real estate / Mortgage',
    value: 'Real estate / Mortgage',
  },
  {
    label: 'Lawyer',
    value: 'Lawyer',
  },
  {
    label: 'Freelancer',
    value: 'Freelancer',
  },
  {
    label: 'Technology',
    value: 'Technology',
  },
  {
    label: 'Sales Representative',
    value: 'Sales Representative',
  },
  {
    label: 'Marketing / Communications',
    value: 'Marketing / Communications',
  },
  {
    label: 'Customer Service',
    value: 'Customer Service',
  },
  {
    label: 'Human Resources',
    value: 'Human Resources',
  },
  {
    label: 'Educator',
    value: 'Educator',
  },
  {
    label: 'Clerical / Administrative Support',
    value: 'Clerical / Administrative Support',
  },
  {
    label: 'Don\'t work / Student',
    value: 'Don\'t work / Student',
  },
  {
    label: 'Others : (Please Specify)',
    value: 'Others : (Please Specify)',
  }
]

export const NEWSLETTER_SUBSCRIPTION_TEXT = `I'd like to subscribe to the "${BRAND_NAME} Newsletter" to stay on top of email marketing, product updates, and special offers.`

export const SETTINGS = {
  "email-verify": {
    label: 'Email Verifier',
    end_point: 'email_verifier'
  },
  "email-finder": {
    label: 'Email Finder',
    end_point: 'email_finder'
  },
  "prospect": {
    label: 'Prospect',
    end_point: 'prospect'
  }
}
