import { ajax } from "./helper";
import * as Constants from "./constants";

class ApiService {

  async onDomainFinder(body, cancelToken) {
    const onDomainFinderUrl = "search/domain?q=" + body;
    return await ajax(onDomainFinderUrl, "GET", null, true, cancelToken);
  }

  async getLeadList(queryParams, cancelToken) {
    const getLeadListUrl = Constants.API_VERSION + "/email_finder/history" + queryParams;
    return await ajax(getLeadListUrl, "GET", null, true, cancelToken);
  }

  async onSingleEmailFinder(body) {
    body['source_agent'] = Constants.USER_SOURCE.INSTANT_FINDER
    const onSingleEmailFinderUrl = Constants.API_VERSION + "/email_finder/instant";
    return await ajax(onSingleEmailFinderUrl, "POST", body);
  }

  async instantEmailFinderHistoryDownload(body) {
    const instantEmailFinderHistoryDownloadUrl = Constants.API_VERSION + "/email_finder/history/download";
    return await ajax(instantEmailFinderHistoryDownloadUrl, "POST", body);
  }

  async onSignUpSubmit(body) {
    const onSignUpSubmitUrl = Constants.API_VERSION + "/user/signup";
    return await ajax(onSignUpSubmitUrl, "POST", body);
  }

  async applyCoupon(body) {
    const applyCouponUrl = Constants.API_VERSION + "/coupon/apply";
    return await ajax(applyCouponUrl, "POST", body);
  }

  async publicInstantSearch(body) {
    body['source_agent'] = Constants.USER_SOURCE.INSTANT_VERIFY
    const instantSearchUrl = 'public/verify'
    return await ajax(instantSearchUrl, 'POST', body, true, null, false)
  }

  async instantSearch(body) {
    body['source_agent'] = Constants.USER_SOURCE.INSTANT_VERIFY
    const instantSearchUrl = Constants.API_VERSION + '/email_verify/instant'
    return await ajax(instantSearchUrl, 'POST', body)
  }

  async verifyToken(body) {
    const verifyTokenUrl = Constants.API_VERSION + "/user/verify_token";
    return await ajax(verifyTokenUrl, "POST", body);
  }

  async signUpUpdate(body) {
    const signUpUpdateUrl = Constants.API_VERSION + "/user/activate";
    return await ajax(signUpUpdateUrl, "POST", body, true, null, true, true);
  }

  async forgotPassword(body) {
    const forgotPasswordUrl = Constants.API_VERSION + "/user/forgot-password";
    return await ajax(forgotPasswordUrl, "POST", body);
  }

  async resetPassword(body) {
    const resetPasswordUrl = Constants.API_VERSION + "/user/reset-password";
    return await ajax(resetPasswordUrl, "POST", body);
  }

  async login(body) {
    const loginUrl = Constants.API_VERSION + "/user/login";
    return await ajax(loginUrl, "POST", body, true, false, true, true);
  }

  async mailChimpAPI() {
    const mailChimpAPIURL = "oauth/mailchimp";
    return await ajax(mailChimpAPIURL, "GET");
  }
  async mailChimpAccounts() {
    const mailChimpAccountsUrl =
      Constants.API_VERSION + "/external/mailchimp/accounts";
    return await ajax(mailChimpAccountsUrl, "GET");
  }

  async getStatusOfIntegratedAccounts() {
    const getStatusUrl =
      Constants.API_VERSION + "/external/linked_accounts";
    return await ajax(getStatusUrl, "GET");
  }

  async mailChimpListByAccount(account_id, body) {
    const mailChimpListByAccountUrl =
      Constants.API_VERSION + "/external/mailchimp/" + account_id + "/list";
    return await ajax(mailChimpListByAccountUrl, "POST", body);
  }

  async mailChimpListAddtoMyLists(account_id, body) {
    const mailChimpListAddtoMyListsUrl =
      Constants.API_VERSION + "/external/mailchimp/" + account_id + "/add_list";
    return await ajax(mailChimpListAddtoMyListsUrl, "POST", body);
  }

  async logout() {
    const logoutUrl = Constants.API_VERSION + "/user/logout";
    return await ajax(logoutUrl, "GET", null, true, false, true, true);
  }

  async editEmailAddress(body) {
    const editEmailAddressUrl = Constants.API_VERSION + "/user/email/edit";
    return await ajax(editEmailAddressUrl, "POST", body);
  }

  async editPhoneNumber(body) {
    const editPhoneNumberUrl = Constants.API_VERSION + "/user/phone/edit";
    return await ajax(editPhoneNumberUrl, "POST", body);
  }

  async editUsername(body) {
    const editUsernameUrl = Constants.API_VERSION + "/user/name/edit";
    return await ajax(editUsernameUrl, "POST", body);
  }

  async editAddress(body) {
    const editAddressUrl = Constants.API_VERSION + "/user/address/edit";
    return await ajax(editAddressUrl, "POST", body);
  }

  async autoPilotStatus(body) {
    const autoPilotUrl = Constants.API_VERSION + "/user_preferences/email_verification_settings/autopilot";
    return await ajax(autoPilotUrl, "PUT", body);
  }

  async updateUserVerificationOptimize(body) {
    const optimizeSettingsUrl = Constants.API_VERSION + "/user_preferences/email_verification_settings/accuracy";
    return await ajax(optimizeSettingsUrl, "PUT", body);
  }

  async updateEmailAddress(body) {
    const updateEmailAddressUrl = Constants.API_VERSION + "/user/email/update";
    return await ajax(updateEmailAddressUrl, "POST", body);
  }

  async updateFreshchatRestoreId(body) {
    const updateFreshchatResctoreIdUrl =
      Constants.API_VERSION + "/user/freshchat/update";
    return await ajax(updateFreshchatResctoreIdUrl, "POST", body);
  }

  async updateUserTimeZone(body) {
    const updateUserTimeZoneUrl = Constants.API_VERSION + "/user/timezone/edit";
    return await ajax(updateUserTimeZoneUrl, "POST", body);
  }

  async changeUserPassword(body) {
    const changeUserPasswordUrl = Constants.API_VERSION + "/user/password/edit";
    return await ajax(changeUserPasswordUrl, "POST", body);
  }

  async fetchAllList(body) {
    const fetchAllListUrl = Constants.API_VERSION + "/email_verify/list";
    return await ajax(fetchAllListUrl, "POST", body);
  }

  async fetchAllEmailFinderList(body) {
    const fetchAllEmailFinderList = Constants.API_VERSION + "/email_finder/list";
    return await ajax(fetchAllEmailFinderList, "POST", body)
  }

  async insightsGraph(body) {
    const insightsSummaryUrl = Constants.API_VERSION + "/insights/graph";
    return await ajax(insightsSummaryUrl, "POST", body);
  }

  async profile() {
    const profileUrl = Constants.API_VERSION + "/user/profile";
    return await ajax(profileUrl, "GET");
  }

  async userPreference(body) {
    const userPreferenceUrl =
      Constants.API_VERSION + "/user_preferences/notification_settings/update";
    return await ajax(userPreferenceUrl, "PUT", body);
  }

  async chunkFileUpload() {
    const chunkFileUploadUrl =
      Constants.API_VERSION + "/email_verify/bulk_upload";
    return await ajax(chunkFileUploadUrl, "POST");
  }

  async generateApiKey() {
    const generateApiKeyUrl = Constants.API_VERSION + "/user/generateapikey";
    return await ajax(generateApiKeyUrl, "GET");
  }

  // payment
  async changeDefaultCard(body) {
    const changeDefaultCardUrl =
      Constants.API_VERSION + "/payment/card/default";
    return await ajax(changeDefaultCardUrl, "POST", body);
  }

  async getDefaultCard() {
    const getDefaultCardUrl = Constants.API_VERSION + "/payment/defaultcard";
    return await ajax(getDefaultCardUrl, "POST");
  }

  async deleteCard(body) {
    const deleteCardUrl = Constants.API_VERSION + "/payment/card/delete";
    return await ajax(deleteCardUrl, "POST", body);
  }

  async addCard(body) {
    const deleteCardUrl = Constants.API_VERSION + "/payment/card/add";
    return await ajax(deleteCardUrl, "POST", body);
  }

  async listCards() {
    const listCardsUrl = Constants.API_VERSION + "/payment/cards";
    return await ajax(listCardsUrl, "GET");
  }

  async listInvoices() {
    const listInvoicesUrl = Constants.API_VERSION + "/invoice/list";
    return await ajax(listInvoicesUrl, "GET");
  }

  //credit
  async buyCredits(body) {
    const buyCreditsUrl = Constants.API_VERSION + "/payment/buy";
    return await ajax(buyCreditsUrl, "POST", body);
  }

  async requestCredits(body) {
    const requestCreditsUrl = Constants.API_VERSION + "/payment/buy/request";
    return await ajax(requestCreditsUrl, "POST", body);
  }

  async listSlabs(body) {
    let appendAuth = body && body.isPublic ? false : true
    let currency = body && body.currency ? body.currency : Constants.CURRENCY.USD.name
    const slabsUrl = Constants.API_VERSION + '/price/slab?currency=' + currency
    return await ajax(slabsUrl, 'GET', null, true, null, appendAuth)
  }

  async priceCalc(credits, cancelToken) {
    const priceCalcUrl = Constants.API_VERSION + "/price?credits=" + credits;
    return await ajax(priceCalcUrl, "GET", null, true, cancelToken);
  }

  async getPaymentIntent(body) {
    const getPaymentIntentUrl = Constants.API_VERSION + "/payment/getPaymentIntent";
    return await ajax(getPaymentIntentUrl, "POST", body);
  }

  async confirmPaymentIntent(body) {
    const confirmPaymentIntentUrl = Constants.API_VERSION + "/payment/confirmPaymentIntent";
    return await ajax(confirmPaymentIntentUrl, "POST", body);
  }

  async downloadResultFile(body) {
    const downloadResultFileUrl = Constants.API_VERSION + "/download/result";
    return await ajax(downloadResultFileUrl, "POST", body);
  }

  async checkUserCreditsStatus() {
    let userCreditStatusUrl =
      Constants.API_VERSION + "/user/check_credit_status";
    return ajax(userCreditStatusUrl, "GET");
  }

  async getRemainingCredits() {
    // '/email_verify/getcredits'
    let getRemainingCreditsUrl =
      Constants.API_VERSION + "/email_verify/getusercredits";
    return ajax(getRemainingCreditsUrl, "GET");
  }

  async buyCreditsClicked() {
    const buyCreditsClickedUrl = Constants.API_VERSION + "/payment/buyCredits";
    return await ajax(buyCreditsClickedUrl, "GET");
  }

  async marketingStats() {
    let marketingStatsUrl = "/public/marketing_stats";
    return ajax(marketingStatsUrl, "GET");
  }

  // async searchLeads(body) {
  //   let searchLeadsUrl = Constants.API_VERSION + "/leads";
  //   return ajax(searchLeadsUrl, "POST", body);
  // }

  // async createLeadsList(body) {
  //   let createLeadsListUrl = Constants.API_VERSION + "/leads/create";
  //   return ajax(createLeadsListUrl, "POST", body);
  // }

  async getList(body, cancelToken) {
    const getJobsListUrl = Constants.API_VERSION + "/email_verify/list";
    return await ajax(getJobsListUrl, "POST", body, true, cancelToken);
  }

  async verifyList(body) {
    body['source_agent'] = Constants.USER_SOURCE.BULK_VERIFY
    const verifyListUrl = Constants.API_VERSION + '/email_verify/list/verify'
    return await ajax(verifyListUrl, 'POST', body)
  }

  async exportList(body) {
    const exportListUrl = Constants.API_VERSION + "/email_verify/list/export";
    return await ajax(exportListUrl, "POST", body);
  }

  async removeInputFile(body) {
    const removeInputFileUrl = Constants.API_VERSION + "/email_verify/list/remove";
    return await ajax(removeInputFileUrl, "POST", body);
  }

  async removeEmailFinderInputFile(body) {
    const removeEmailFinderInputFileUrl = Constants.API_VERSION + "/email_finder/list/remove"
    return await ajax(removeEmailFinderInputFileUrl, "POST", body)
  }

  //admin api's
  async addUsers(body) {
    const addUsersUrl = Constants.API_VERSION + "/admin/member/add";
    return await ajax(addUsersUrl, "POST", body);
  }

  async editUsers(body) {
    const editUsersUrl = Constants.API_VERSION + "/admin/member/edit";
    return await ajax(editUsersUrl, "POST", body);
  }

  async removeUsers(body) {
    const removeUsersUrl = Constants.API_VERSION + "/admin/member/remove";
    return await ajax(removeUsersUrl, "POST", body);
  }

  async userStatusUpdate(body) {
    const removeUsersUrl = Constants.API_VERSION + "/admin/member/update_status";
    return await ajax(removeUsersUrl, "POST", body);
  }

  async reactivateUsers(body) {
    const removeUsersUrl = Constants.API_VERSION + "/admin/member/reactivate";
    return await ajax(removeUsersUrl, "POST", body);
  }

  //seat request api
  async requestForSeats(body) {
    const addUsersUrl = Constants.API_VERSION + "/admin/member/seatrequest";
    return await ajax(addUsersUrl, "POST", body);
  }

  async allUsersList(body) {
    const addUsersUrl = Constants.API_VERSION + "/admin/member/list";
    return await ajax(addUsersUrl, "POST", body);
  }

  async addTeam(body) {
    const addTeamUrl = Constants.API_VERSION + "/admin/team/create";
    return await ajax(addTeamUrl, "POST", body);
  }
  async allTeamList(body) {
    const allTeamListUrl = Constants.API_VERSION + "/admin/team/list";
    return await ajax(allTeamListUrl, "POST", body);
  }

  async individualTeamList(body) {
    const allTeamListUrl = Constants.API_VERSION + "/admin/team/members";
    return await ajax(allTeamListUrl, "POST", body);
  }

  async editTeam(body) {
    const createTeamUrl = Constants.API_VERSION + "/admin/team/edit";
    return await ajax(createTeamUrl, "POST", body);
  }

  async removeBulkMember(body) {
    const removeMemberUrl = Constants.API_VERSION + "/admin/bulk/remove";
    return await ajax(removeMemberUrl, "POST", body);
  }

  async editOrgDetails(body) {
    const editOrgDetailsUrl = Constants.API_VERSION + "/admin/edit";
    return await ajax(editOrgDetailsUrl, "POST", body);
  }

  async getPerformanceStats(body) {
    const getPerformanceStatsUrl =
      Constants.API_VERSION + "/insights/performance_stats";
    return await ajax(getPerformanceStatsUrl, "POST", body);
  }
  //active campaign

  async activeCampaignAccounts() {
    const activeCampaignAccountsUrl =
      Constants.API_VERSION + "/external/active_campaign/accounts";
    return await ajax(activeCampaignAccountsUrl, "GET");
  }

  async activeCampaignListByAccount(account_id, body) {
    const activeCampaignListByAccountUrl =
      Constants.API_VERSION +
      "/external/active_campaign/account/" +
      account_id +
      "/lists";
    return await ajax(activeCampaignListByAccountUrl, "POST", body);
  }

  async activeCampaignListAddtoMyLists(account_id, body) {
    const mailChimpListaAddtoMyListsUrl =
      Constants.API_VERSION +
      "/external/active_campaign/" +
      account_id +
      "/add_list";
    return await ajax(mailChimpListaAddtoMyListsUrl, "POST", body);
  }

  async addActiveCampaignAccount(body) {
    const addActiveCampaignAccountUrl =
      Constants.API_VERSION + "/external/active_campaign/account/add";
    return await ajax(addActiveCampaignAccountUrl, "POST", body);
  }

  //clevertap

  async clevertapAccounts() {
    const clevertapAccountsUrl =
      Constants.API_VERSION + "/external/clevertap/accounts";
    return await ajax(clevertapAccountsUrl, "GET");
  }

  async clevertapListByAccount(account_id, body) {
    const clevertapListByAccountUrl =
      Constants.API_VERSION +
      "/external/clevertap/account/" +
      account_id +
      "/lists";
    return await ajax(clevertapListByAccountUrl, "POST", body);
  }

  async clevertapListAddtoMyLists(account_id, body) {
    const clevertapAddtoMyListsUrl =
      Constants.API_VERSION +
      "/external/clevertap/" +
      account_id +
      "/add_list";
    return await ajax(clevertapAddtoMyListsUrl, "POST", body);
  }

  async addCleverTapAccount(body) {
    const addCleverTapAccountUrl =
      Constants.API_VERSION + "/external/clevertap/account/add";
    return await ajax(addCleverTapAccountUrl, "POST", body);
  }

  //moosend

  async addMoosendAccount(body) {
    const moosendAddAccountsUrl =
      Constants.API_VERSION + "/external/moosend/account/add";
    return await ajax(moosendAddAccountsUrl, "POST", body);
  }

  async getMoosendAccounts() {
    const moosendAddAccountsUrl =
      Constants.API_VERSION + "/external/moosend/accounts";
    return await ajax(moosendAddAccountsUrl, "GET");
  }

  async getMoosendAccountList(account_id) {
    const getMoosendAccountListUrl =
      Constants.API_VERSION +
      "/external/moosend/account/" +
      account_id +
      "/lists";
    return await ajax(getMoosendAccountListUrl, "POST");
  }

  async addToMyListMoosend(account_id, body) {
    const addToMyListMoosendUrl =
      Constants.API_VERSION + "/external/moosend/" + account_id + "/add_list";
    return await ajax(addToMyListMoosendUrl, "POST", body);
  }

  //Apollo
  async addApolloAccount(body) {
    const apolloAddAccountsUrl =
      Constants.API_VERSION + "/external/apollo/account/add";
    return await ajax(apolloAddAccountsUrl, "POST", body);
  }

  async getApolloAccounts() {
    const apolloAddAccountsUrl =
      Constants.API_VERSION + "/external/apollo/accounts";
    return await ajax(apolloAddAccountsUrl, "GET");
  }

  async getApolloAccountList(account_id) {
    const getApolloAccountListUrl =
      Constants.API_VERSION +
      "/external/apollo/account/" +
      account_id +
      "/lists";
    return await ajax(getApolloAccountListUrl, "GET");
  }


  async addToMyListApollo(account_id, body) {
    const addToMyListApolloUrl =
      Constants.API_VERSION + "/external/apollo/" + account_id + "/add_list";
    return await ajax(addToMyListApolloUrl, "POST", body);
  }

  // hubspot
  async hubspotAccounts() {
    const hubspotAccountsUrl =
      Constants.API_VERSION + "/external/hubspot/accounts";
    return await ajax(hubspotAccountsUrl, "GET");
  }

  async hubspotListAddToMyLists(account_id, body) {
    const hubspotListAddToMyListsUrl =
      Constants.API_VERSION + "/external/hubspot/" + account_id + "/add_list";
    return await ajax(hubspotListAddToMyListsUrl, "POST", body);
  }

  async hubspotListByAccount(account_id, body) {
    const hubspotListByAccountUrl =
      Constants.API_VERSION +
      "/external/hubspot/account/" +
      account_id +
      "/lists";
    return await ajax(hubspotListByAccountUrl, "POST", body);
  }

  //mailerlite

  async addMailerliteAccount(body) {
    const addMailerliteAccountUrl =
      Constants.API_VERSION + "/external/mailerlite/account/add";
    return await ajax(addMailerliteAccountUrl, "POST", body);
  }

  async getMailerliteAccounts() {
    const getMailerliteAccountsUrl =
      Constants.API_VERSION + "/external/mailerlite/accounts";
    return await ajax(getMailerliteAccountsUrl, "GET");
  }

  async getMailerliteAccountList(account_id) {
    const getMailerliteAccountListUrl =
      Constants.API_VERSION +
      "/external/mailerlite/account/" +
      account_id +
      "/lists";
    return await ajax(getMailerliteAccountListUrl, "POST");
  }

  async addToMyListMailerlite(account_id, body) {
    const addToMyListMailerliteUrl =
      Constants.API_VERSION +
      "/external/mailerlite/" +
      account_id +
      "/add_list";
    return await ajax(addToMyListMailerliteUrl, "POST", body);
  }

  //sendgrid
  async addSendgridAccount(body) {
    const sendgridAddAccountsUrl =
      Constants.API_VERSION + "/external/sendgrid/account/add";
    return await ajax(sendgridAddAccountsUrl, "POST", body);
  }

  async getSendgridAccounts() {
    const sendgridAddAccountsUrl =
      Constants.API_VERSION + "/external/sendgrid/accounts";
    return await ajax(sendgridAddAccountsUrl, "GET");
  }

  async getSendgridAccountList(account_id) {
    const getSendgridAccountListUrl =
      Constants.API_VERSION +
      "/external/sendgrid/account/" +
      account_id +
      "/lists";
    return await ajax(getSendgridAccountListUrl, "POST");
  }

  async addToMyListSendgrid(account_id, body) {
    const addToMyListSendgridUrl = Constants.API_VERSION + "/external/sendgrid/" + account_id + "/add_list";
    return await ajax(addToMyListSendgridUrl, "POST", body);
  }

  //automizy
  async addAutomizyAccount(body) {
    const automizyAddAccountsUrl =
      Constants.API_VERSION + "/external/automizy/account/add";
    return await ajax(automizyAddAccountsUrl, "POST", body);
  }

  async getAutomizyAccounts() {
    const automizyAddAccountsUrl =
      Constants.API_VERSION + "/external/automizy/accounts";
    return await ajax(automizyAddAccountsUrl, "GET");
  }

  async getAutomizyAccountList(account_id) {
    const getAutomizyAccountListUrl =
      Constants.API_VERSION +
      "/external/automizy/account/" +
      account_id +
      "/lists";
    return await ajax(getAutomizyAccountListUrl, "POST");
  }

  async addToMyListAutomizy(account_id, body) {
    const addToMyListAutomizyUrl = Constants.API_VERSION + "/external/automizy/" + account_id + "/add_list";
    return await ajax(addToMyListAutomizyUrl, "POST", body);
  }

  async createPaypalOrder(credits) {
    const createPaypalOrderUrl = Constants.API_VERSION + "/payment/paypal/order/create";
    return await ajax(createPaypalOrderUrl, "POST", credits);
  }

  async verifyPaypalOrder(body) {
    const verifyPaypalOrderUrl = Constants.API_VERSION + "/payment/paypal/order/verify";
    return await ajax(verifyPaypalOrderUrl, "POST", body);
  }

  async capturePaypalOrder(body) {
    const capturePaypalOrderUrl = Constants.API_VERSION + "/payment/paypal/order/capture";
    return await ajax(capturePaypalOrderUrl, "POST", body);
  }

  async adminLowCreditUpdate(body) {
    const lowCreditUpdateUrl = Constants.API_VERSION + "/admin/low_credit_alert/update";
    return await ajax(lowCreditUpdateUrl, "POST", body);
  }

  async userLowCreditUpdate(body) {
    const lowCreditUpdateUrl =
      Constants.API_VERSION + "/user/low_credit_alert/update";
    return await ajax(lowCreditUpdateUrl, "POST", body);
  }

  async userUpgradeAccount(body) {
    const userUpgradeAccountUrl = Constants.API_VERSION + "/user/upgrade";
    return await ajax(userUpgradeAccountUrl, "POST", body);
  }


  //subscription
  async getSubscriptionPlans(body) {
    let appendAuth = body && body.isPublic ? false : true
    const subscriptionPlansUrl = Constants.API_VERSION + '/price/plan'
    return await ajax(subscriptionPlansUrl, 'GET', null, true, null, appendAuth)
  }

  async checkSubscription(body) {
    const subscribeUrl =
      Constants.API_VERSION + "/payment/paypal/plan/subscription";
    return await ajax(subscribeUrl, "POST", body);
  }

  async createSubcription(body) {
    const createSubsUrl = Constants.API_VERSION + "/payment/paypal/create/subscription"
    return await ajax(createSubsUrl, "POST", body)
  }

  async updateSubscribe(body) {
    const subscribeUrl = Constants.API_VERSION + "/payment/paypal/subscription";
    return await ajax(subscribeUrl, "POST", body);
  }

  async cancelSubscription(body) {
    const cancelSubscriptionUrl =
      Constants.API_VERSION + "/payment/subscription/cancel?reason=" + body;
    return await ajax(cancelSubscriptionUrl, "GET");
  }

  async currentPlanInfo() {
    const currentPlanInfoUrl = Constants.API_VERSION + "/user/current_plan";
    return await ajax(currentPlanInfoUrl, "GET");
  }

  async checkIpLocation(token) {
    const checkIpLocationUrl = "public/ip_location";
    return await ajax(checkIpLocationUrl, "GET", null, true, token, false);
  }

  async userActivities(body) {
    const userActivitiesUrl = Constants.API_VERSION + '/user/activities'
    return await ajax(userActivitiesUrl, 'POST', body)
  }

  async usageReports(body) {
    const usageReportsUrl = Constants.API_VERSION + '/reports/usage'
    return await ajax(usageReportsUrl, 'POST', body)
  }

  async finderUsageReports(body) {
    const usageReportsUrl = Constants.API_VERSION + '/reports/finder_usage'
    return await ajax(usageReportsUrl, 'POST', body)
  }

  async prospectUsageReports(body) {
    const usageReportsUrl = Constants.API_VERSION + '/reports/prospect_usage'
    return await ajax(usageReportsUrl, 'POST', body)
  }

  async overAllUsageReports(body) {
    const usageReportsUrl = Constants.API_VERSION + '/reports/over_view_usage'
    return await ajax(usageReportsUrl, 'POST', body)
  }

  async createApp(body) {
    const userAppCreateUrl = Constants.API_VERSION + '/apps/create'
    return await ajax(userAppCreateUrl, 'POST', body)
  }

  async updateApp(body) {
    const userAppUpdateUrl = Constants.API_VERSION + '/apps/update'
    return await ajax(userAppUpdateUrl, 'POST', body)
  }

  async listApp() {
    const userAppListUrl = Constants.API_VERSION + '/apps/list'
    return await ajax(userAppListUrl, 'GET')
  }

  async getAnnouncementBarText() {
    const announcementBarTextUrl = Constants.API_VERSION + '/user/announcement'
    return await ajax(announcementBarTextUrl, 'GET')
  }

  async verifyOneToolToken(body) {
    const verifyOneToolTokenUrl = "onetool/verify_token";
    return await ajax(verifyOneToolTokenUrl, "POST", body);
  }

  async OneToolSignUpUpdate(body) {
    const oneToolSignUpUpdateUrl = "onetool/login";
    return await ajax(oneToolSignUpUpdateUrl, "POST", body);
  }

  async downloadMailResultFile(download_token) {
    const downloadListUrl = 'public/download?download_token=' + download_token
    return await ajax(downloadListUrl, 'GET', null, true, null, false)
  }

  async getEmailFinderList(body, cancelToken) {
    const getEmailFinderListUrl = Constants.API_VERSION + "/email_finder/list";
    return await ajax(getEmailFinderListUrl, "POST", body, true, cancelToken);
  }

  async findEmailsList(body) {
    body['source_agent'] = Constants.USER_SOURCE.BULK_FINDER
    const findEmailsListUrl = Constants.API_VERSION + '/email_finder/list/find_emails'
    return await ajax(findEmailsListUrl, 'POST', body)
  }

  async downloadEmailFinderResult(body) {
    const downloadEmailFinderListUrl = Constants.API_VERSION + '/email_finder/download/result'
    return await ajax(downloadEmailFinderListUrl, 'POST', body)
  }

  async downloadEmailFinderHistory(download_token) {
    const downloadListUrl = 'public/download_email_finder_history?download_token=' + download_token
    return await ajax(downloadListUrl, 'GET', null, true, null, false)
  }

  async instantEmailFinderStatus(qid) {
    const instantEmailfinderStatusUrl = Constants.API_VERSION + '/email_finder/instant/queue_status?qid=' + qid
    return await ajax(instantEmailfinderStatusUrl, 'GET')
  }

  // User WhiteBlackList Settings
  async listWhiteBlackListValues(qid) {
    const listWhiteBlackListValuesUrl = Constants.API_VERSION + '/user_preferences/email_verification_settings/wb_list' + qid
    return await ajax(listWhiteBlackListValuesUrl, 'GET')
  }

  async editWhiteBlackListValues(body) {
    const editWhiteBlackListValuesUrl = Constants.API_VERSION + '/user_preferences/email_verification_settings/wb_list'
    return await ajax(editWhiteBlackListValuesUrl, 'PUT', body)
  }

  // User Email Finder Settings
  async includeRoleEmail(body) {
    const includeRoleEmailUrl = Constants.API_VERSION + '/user_preferences/email_finder_settings/role'
    return await ajax(includeRoleEmailUrl, 'PUT', body)
  }

  async checkEVListStatusToRemove(qid) {
    const getEVListStatusUrl = Constants.API_VERSION + '/email_verify/list/remove' + qid
    return await ajax(getEVListStatusUrl, 'GET')
  }

  async checkEFListStatusToRemove(qid) {
    const getEFListStatusUrl = Constants.API_VERSION + '/email_finder/list/remove' + qid
    return await ajax(getEFListStatusUrl, 'GET')
  }

  async emailVerifyBulkUploadDuplicateFileName(body) {
    const evBulkUploadDuplicateFileNameUrl = Constants.API_VERSION + '/email_verify/bulk_upload/check_duplicate'
    return await ajax(evBulkUploadDuplicateFileNameUrl, 'POST', body)
  }

  async emailFinderBulkUploadDuplicateFileName(body) {
    const efBulkUploadDuplicateFileNameUrl = Constants.API_VERSION + '/email_finder/bulk_upload/check_duplicate'
    return await ajax(efBulkUploadDuplicateFileNameUrl, 'POST', body)
  }

  async cancelEmailVerify(body) {
    const cancelEvUrl = Constants.API_VERSION + "/email_verify/list/cancel";
    return await ajax(cancelEvUrl, "POST", body);
  }

  async cancelEmailFinder(body) {
    const cancelEvUrl = Constants.API_VERSION + "/email_finder/list/cancel";
    return await ajax(cancelEvUrl, "POST", body);
  }

  async getBulkEmailFinderListInProgress(list_id) {
    const getEfListInProgress =
      Constants.API_VERSION + "/email_finder/bulk/progress_status?list_id=" + list_id
    return await ajax(getEfListInProgress, "GET");
  }

  async getBulkEmailVerifyListInProgress(list_id) {
    const getEvListInProgress =
      Constants.API_VERSION + "/email_verify/bulk/progress_status?list_id=" + list_id
    return await ajax(getEvListInProgress, "GET");
  }

  // Prospects

  async getSearchFilterMeta(queryParams, cancelToken) {
    const searchMetadataUrl = Constants.API_VERSION + '/prospect/search/meta' + queryParams
    return await ajax(searchMetadataUrl, 'GET', null, true, cancelToken)
  }

  async getUserProspectLists(queryParams) {
    const getUserProspectListsUrl = Constants.API_VERSION + '/prospect/list' + queryParams
    return await ajax(getUserProspectListsUrl, 'GET')
  }

  async saveSearches(body) {
    const saveSearchesUrl = Constants.API_VERSION + '/prospect/search/save_filter'
    return await ajax(saveSearchesUrl, "POST", body)
  }

  async getSavedSearchesList(queryParams) {
    const getSavedSearchesListUrl = Constants.API_VERSION + '/prospect/search/filters' + queryParams
    return await ajax(getSavedSearchesListUrl, "GET")
  }

  async removeProspects(body) {
    const removeProspectsUrl = Constants.API_VERSION + '/prospect/remove'
    return await ajax(removeProspectsUrl, 'POST', body)
  }

  async enrichProspectList(body) {
    body['source_agent'] = Constants.USER_SOURCE.ENRICH_LIST
    const enrichProspectListUrl = Constants.API_VERSION + '/prospect/list/enrich'
    return await ajax(enrichProspectListUrl, 'POST', body)
  }

  async exportProspectList(body) {
    const exportProspectListUrl = Constants.API_VERSION + '/prospect/list/export'
    return await ajax(exportProspectListUrl, "POST", body)
  }

  async enrichProspect(body) {
    body['source_agent'] = Constants.USER_SOURCE.ENRICH_LEAD
    const enrichProspectUrl = Constants.API_VERSION + '/prospect/enrich'
    return await ajax(enrichProspectUrl, "POST", body)
  }

  async enrichProspectStatus(queryParams) {
    const enrichProspectStatusUrl = Constants.API_VERSION + '/prospect/lead/enrich/status' + queryParams
    return await ajax(enrichProspectStatusUrl, "GET")
  }

  async updateProspectList(body) {
    const updateProspectListUrl = Constants.API_VERSION + '/prospect/list/update'
    return await ajax(updateProspectListUrl, "POST", body)
  }

  async getInProgressSearchProfilesRequests(queryParams, cancelToken) {
    const searchLeadsUrl = Constants.API_VERSION + '/prospect/requests' + queryParams
    return await ajax(searchLeadsUrl, 'GET', null, true, cancelToken)
  }

  async getProspectInProgressRequests(queryParams, cancelToken) {
    const getProspectInProgressRequestsUrl = Constants.API_VERSION + '/prospect/inprogress_requests' + queryParams
    return await ajax(getProspectInProgressRequestsUrl, 'GET', null, true, cancelToken)
  }

  async getRecentProspectAdded(queryParams) {
    const getRecentProspectAddedUrl = Constants.API_VERSION + '/prospect/recent_added' + queryParams
    return await ajax(getRecentProspectAddedUrl, 'GET')
  }

  async getProspectListsWithDetails(queryParams, cancelToken) {
    const leadListsUrl = Constants.API_VERSION + '/prospect/list/details' + queryParams
    return await ajax(leadListsUrl, 'GET', null, true, cancelToken)
  }

  async getSearchProfiles(queryParams) {
    const leadsFromListUrl = Constants.API_VERSION + '/prospect/search/profiles' + queryParams
    return await ajax(leadsFromListUrl, 'GET')
  }

  async searchProspect(body) {
    const searchUrl = Constants.API_VERSION + '/prospect/search'
    return await ajax(searchUrl, 'POST', body)
  }

  async profileView(body) {
    const searchLeadUrl = Constants.API_VERSION + '/prospect/view'
    return await ajax(searchLeadUrl, 'POST', body)
  }

  async leadInList(queryParams) {
    const leadInListUrl = Constants.API_VERSION + '/prospect/list/lead_in_list' + queryParams
    return await ajax(leadInListUrl, 'GET')
  }

  async removeProspectFromList(body) {
    const removeProspectFromListUrl = Constants.API_VERSION + '/prospect/list/remove_lead'
    return await ajax(removeProspectFromListUrl, 'POST', body)
  }

  async removeProspectList(body) {
    const removeProspectList = Constants.API_VERSION + '/prospect/list/remove'
    return await ajax(removeProspectList, 'POST', body)
  }

  async instantLeadsDownload(body) {
    const instantLeadsDownloadUrl = Constants.API_VERSION + "/prospect/download";
    return await ajax(instantLeadsDownloadUrl, "POST", body);
  }

  async downloadProspects(download_token) {
    const downloadProspectsUrl = 'public/download_prospects?download_token=' + download_token
    return await ajax(downloadProspectsUrl, 'GET', null, true, null, false)
  }

  async addProspects(body) {
    body['source_agent'] = Constants.USER_SOURCE.ADD_LEAD
    const addProspectsUrl = Constants.API_VERSION + '/prospect/search/add_profiles'
    return await ajax(addProspectsUrl, 'POST', body)
  }

  async searchInList(body, cancelToken) {
    const searchInListUrl = Constants.API_VERSION + '/prospect/list/search/leads' + body
    return await ajax(searchInListUrl, 'GET', null, true, cancelToken)
  }

  async getProspectRecentActivity(queryParams, cancelToken) {
    const getProspectRecentActivityUrl = Constants.API_VERSION + '/prospect/recent_activities' + queryParams
    return await ajax(getProspectRecentActivityUrl, 'GET', null, true, cancelToken)
  }

  async getExtensionVersion(queryParams) {
    const getExtensionVersionUrl = 'extension/version?v=' + queryParams
    return await ajax(getExtensionVersionUrl, 'GET')
  }

  async removeSavedFilter(body) {
    const removeSavedFilterUrl = Constants.API_VERSION + '/prospect/search/remove_filter'
    return await ajax(removeSavedFilterUrl, "POST", body)
  }

  async createSetupIntent(body) {
    const createSetupIntentUrl = Constants.API_VERSION + "/payment/setup_intent/create";
    return await ajax(createSetupIntentUrl, "POST", body);
  }

  async confirmSetupIntent(body) {
    const confirmSetupIntentUrl = Constants.API_VERSION + "/payment/setup_intent/confirm";
    return await ajax(confirmSetupIntentUrl, "POST", body);
  }
  /* SSO LOGINS */
  async googleSSO(body) {
    const googleSSOUrl = "sso/google";
    return await ajax(googleSSOUrl, "POST", body, true, false, true, true);
  }

  async googleSSOActivate(body) {
    const googleSSOActivateUrl = "sso/google/activate";
    return await ajax(googleSSOActivateUrl, "POST", body, true, false, true, true);
  }

  /* Google account management */
  async getIntegrationAcct(integration) {
    const getIntegrationAcctUrl = Constants.API_VERSION + `/integration/account/${integration}`;
    return await ajax(getIntegrationAcctUrl, "GET");
  }

  async removeIntegrationAccount(body) {
    const removeIntegrationAccount = Constants.API_VERSION + `/integration/account/${body.integration}/remove`;
    return await ajax(removeIntegrationAccount, "POST", body, true, false, true, false);
  }
  /* Prospect settings */
  async updateEnrichSettings(body) {
    const prospectSettings = Constants.API_VERSION + '/user_preferences/prospect_settings/enrich'
    return await ajax(prospectSettings, 'PUT', body);
  }

  async getG2CrowdUrl(queryParams) {
    const g2crowdUrl = Constants.API_VERSION + `/g2crowd/url${queryParams}`
    return await ajax(g2crowdUrl, "GET")
  }
}

export default new ApiService();
