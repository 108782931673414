import React, { Component } from 'react'
import {
  Navbar, NavItem, Collapse, NavbarToggler, UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem

} from 'reactstrap'
import { NavLink, Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
// import moment from 'moment'
import { connect } from 'react-redux'
import { NavLink as NavigationLink } from 'reactstrap'

import appEventEmitter, { AppEvents } from '../../helpers/AppEventEmitter'
import ApiService from '../../helpers/apiservice'
import * as Constants from '../../helpers/constants'
import * as ThemeConstants from '../../helpers/themeConstants'
import * as Helpers from '../../helpers/helper'
import CustomMenu from './custom_menu'
import PricingApiCall from '../../helpers/commonApiCalls'
import { isMobile } from "react-device-detect"
import AnnounceKit from 'announcekit-react';
//import * as LeadConstants from './../leadList/constants'
import { ModalControllerContext } from '../../controller/modal_controller'
import { logoutSuccess } from '../user/user_reducer'
import G2CrowdModal from '../widgets/g2crowd_modal'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      collapsed: true,
      menus: [],
      disableLogout: false
    }
    this.menuDropdownRef = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.activated_on != prevProps.user.activated_on) {
      this.getHeaderMenus()
    }
  }

  componentDidMount() {
    this.setState({
      menus: HEADER_MENU_ORDER
    })
    if (this.props.appReducer && !this.props.appReducer.minCredit || this.props.appReducer.slabs === null) {
      PricingApiCall.listSlabs(this.props.dispatch)
    }
    document.addEventListener('mousedown', this.handleClickOutside)
    if (window) {
      if (!window.clearout) {
        window.clearout = []
      }
      window.clearout["header"] = this
    } else {
      console.log("Error: window object not found, app not running in brwoser environment")
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      collapsed: !this.state.collapsed
    })
  }

  closeNavbar = () => {
    if (this.state.collapsed != true) {
      this.toggle()
    }
  }

  showUpgradeButton = () => {
    let showButton = false
    if (Constants.OWNER_RESTRICT_ROLES.includes(this.props.user.user_role)) {
      showButton = true
    }
    return showButton
  }

  onClickUpgradeButton = () => {
    appEventEmitter.emit(AppEvents.pricing_page_loaded, { app_version: APP_VERSION })
    this.closeNavbar
  }

  getUpgradeButton = () => {
    if (this.showUpgradeButton()) {
      return (
        <li
          key='upgrade-button'
          className="nav-item upgrade-nav"
        >
          {PAYMENT_METHOD === 'credit_request' && !ACCOUNT_MENUS.includes('pricing')
            ? <a onClick={this.onClickBuyCreditsButton}>
              <span className="upgrade-btn" data-tip data-for="upgradePricing">
                Buy Credits
              </span>
              {isMobile ? null :
                <ReactTooltip className="tooltip-clearout" id="upgradePricing" place="bottom" multiline={true}>
                  <span>{Constants.HEADER_SUBS_PRICING_DISCOUNT_TEXT}</span>
                </ReactTooltip>}
            </a>
            : <Link to="/dashboard/account/pricing"
              onClick={() => this.onClickUpgradeButton()}
            >
              <span className="upgrade-btn" data-tip data-for="upgradePricing">
                Buy Credits
              </span>
              {isMobile ? null :
                <ReactTooltip className="tooltip-clearout" id="upgradePricing" place="bottom" multiline={true}>
                  <span>{Constants.HEADER_SUBS_PRICING_DISCOUNT_TEXT}</span>
                </ReactTooltip>}
            </Link>
          }
        </li>
      )
    }
  }

  onClickBuyCreditsButton = () => {
    appEventEmitter.emit(AppEvents.user_clicked_on_buy_credits, { app_version: APP_VERSION })
    appEventEmitter.emit(AppEvents.buy_credit_modal_open, { app_version: APP_VERSION })
    isMobile && this.closeNavbar()
    let modalController = this.context
    modalController.dispatchModal({
      name: Constants.CREDIT_MODAL,
      isOpen: true
    })
  }

  showAdminButton = () => {
    let showButton = false
    if (Constants.ADMIN_RESTRICT_ROLES.includes(
      this.props.user.user_role
    ) && this.props.user.org_name) {
      showButton = true
    }
    return showButton
  }

  getAdminButton = () => {
    if (this.showAdminButton()) {
      return isMobile ?
        <NavItem key='admin'>
          <NavLink
            activeClassName="active"
            to='/dashboard/admin'
            className=" nav-link"
            onClick={() => this.closeNavbar()}
          >
            Admin
          </NavLink>
        </NavItem> :
        <DropdownItem key='admin'>
          <NavLink
            activeClassName="active"
            to='/dashboard/admin'
            className=" nav-link"
            onClick={() => this.closeNavbar()}
          >
            {isMobile ? 'Admin' : <span className="header-dropdown-icon"
              data-tip data-for="admin-tooltip">
              <i className="fa fa-cube"></i>
              <span>Admin</span>
              <ReactTooltip className="tooltip-clearout" id="admin-tooltip" place="bottom" multiline={true}>
                <span>Manage Teams and Members</span>
              </ReactTooltip>
            </span>}
          </NavLink>
        </DropdownItem>
    }
  }

  getWidgetButton = () => {
    return (
      <li
        key='widget-button'
        className="nav-item widget-nav"
      >
        <a className="header-item" onClick={() => this.props.openAnnounceKitWidget()}>
          <AnnounceKit ref={this.props.widgetAnnounceKitRef}
            widget="https://announcekit.app/widgets/v2/2TreH6"
            widgetStyle={{
              borderRadius: "50%",
              fontSize: "1em",
              marginLeft: "-7px",
              marginTop: "-15px",
              textAlign: "center",
              lineHeight: "1.7rem"
            }}
            user={{
              id: this.props.user.id,
              name: this.props.user.name,
              email: this.props.user.email
            }}
          >
            <span data-tip data-for="widget">
              <i className="fa fa-bell-o"></i>
            </span>
            {isMobile ? null :
              <ReactTooltip className="tooltip-clearout" id="widget" place="bottom" multiline={true}>
                <span>{"What's new?"}</span>
              </ReactTooltip>}
          </AnnounceKit>
        </a>
      </li>
    )
  }

  getHelpButton = () => {
    let modelController = this.context;
    return isMobile ?
      <NavItem key='help'>
        <NavLink
          to="#"
          activeClassName="active"
          className=" nav-link"
          onClick={() => {
            appEventEmitter.emit(AppEvents.welcome_popup_modal_open_onclick, {
              app_version: APP_VERSION,
            });
            modelController.dispatchModal({ name: Constants.WELCOME_MODAL, isOpen: true, forceOpen: true })
          }}
        >
          Help
        </NavLink>
      </NavItem>
      :
      <DropdownItem key='help'
        onClick={() => {
          appEventEmitter.emit(AppEvents.welcome_popup_modal_open_onclick, {
            app_version: APP_VERSION,
          });
          modelController.dispatchModal({ name: Constants.WELCOME_MODAL, isOpen: true, forceOpen: true })
        }}
        style={{ cursor: 'pointer' }}>
        <span className="header-dropdown-icon"
          data-tip data-for="help-tooltip">
          <i className="fa fa-question-circle-o"></i>
          <span>Help</span>
          <ReactTooltip className="tooltip-clearout" id="help-tooltip" place="bottom" multiline={true}>
            <span>Get Help</span>
          </ReactTooltip>
        </span>
      </DropdownItem>
  }

  getHeaderMenus = () => {
    this.setState({
      menus: HEADER_MENU_ORDER
    })
  }

  signOutClick = () => {
    appEventEmitter.emit(AppEvents.user_clicked_logout_btn, {
      app_version: APP_VERSION
    })
    if (window.fcWidget) {
      window.fcWidget.user.get(res => {
        if (res.success) {
          window.fcWidget.user.clear()
        }
      })
    }
    this.setState({
      disableLogout: true
    })
    ApiService.logout()
      .then(res => {
        if (res.status == Constants.CO_API.Success) {
          this.props.dispatch(logoutSuccess())
          Helpers.clearLocalStorage()
          appEventEmitter.emit(AppEvents.user_signout_success, {
            app_version: APP_VERSION
          })
        }
      })
      .catch(err => {
        console.log("Logout api erred", err)
        this.props.dispatch(logoutSuccess())
        Helpers.clearLocalStorage()
      })
  }

  getLogoutButton = () => {
    return isMobile ?
      <NavItem key='logout'>
        <NavigationLink
          className={"btn-clearout-link font-size-2 header-item"}
          onClick={this.signOutClick}
          title={"Logout"}
          disabled={this.state.disableLogout}
        >
          Logout
        </NavigationLink>
      </NavItem>
      :
      <DropdownItem key='logout'>
        <NavigationLink
          className={"btn-clearout-link header-item"}
          onClick={this.signOutClick}
          disabled={this.state.disableLogout}
        >
          <span className="header-dropdown-icon">
            <i className="fa fa-sign-out" style={{ color: "#03a9f4" }}></i>
            <span>Logout</span>
          </span>
        </NavigationLink>
      </DropdownItem>
  }

  getProspectsButton = () => {
    return (
      <li
        key='prospects-button'
        className="nav-item prospects-nav"
      >
        <Link to="/dashboard/prospect">
          Prospect
        </Link>
      </li>
    )
  }

  getG2CrowdButton = () => {
    return (
      <G2CrowdModal
        key="g2-review-button"
        user={this.props.user}
      />
    )
  }

  getMenuButton = (menu) => {
    switch (menu) {
      case 'upgrade':
        return this.getUpgradeButton()
      case 'widget':
        return this.getWidgetButton()
      case 'admin':
        return this.getAdminButton()
      case 'logout':
        return this.getLogoutButton()
      case 'prospects':
        return this.getProspectsButton()
      case 'help':
        return this.getHelpButton()
      case 'g2crowd':
        return this.getG2CrowdButton()
    }
  }

  render() {
    return (
      <div className="main-header">
        {isMobile ?
          <Navbar expand="md" className="container">
            <NavLink to="/dashboard" className="navbar-brand">
              <img className="logo-default" src={IMAGES_LOGO_SYMBOL} alt="logo" />
            </NavLink>
            <Collapse className="ml-auto" isOpen={true} >
              <ul className="navbar-nav flex-row" ref={(input) => { this.menuDropdownRef = input; }}>
                {this.state.menus.filter(m => ThemeConstants.MENUS[m].mobileLocator === "header").map((menu) => {
                  let menuDetails = ThemeConstants.MENUS[menu]

                  return Constants.CUSTOM_HEADER_MENUS.includes(menu) ?
                    this.getMenuButton(menu)
                    : <NavItem
                      key={menuDetails.value}
                    >
                      <CustomMenu
                        menu={menuDetails}
                        closeNavbar={this.closeNavbar}
                      >
                      </CustomMenu>
                    </NavItem>
                })}
              </ul>
            </Collapse>
            <NavbarToggler onClick={this.toggle} />
            <Collapse style={{ flexGrow: "0" }} isOpen={!this.state.collapsed} navbar >
              <ul className="navbar-nav" ref={(input) => { this.menuDropdownRef = input; }}>
                {this.state.menus.filter(m => ThemeConstants.MENUS[m].mobileLocator === "dropdown").map((menu) => {
                  let menuDetails = ThemeConstants.MENUS[menu]

                  var clone_header_menu = Constants.CUSTOM_HEADER_MENUS;

                  if (!SHOW_WELCOME_POPUP || Constants.WELCOME_MODAL_PRIORITY < 1) {
                    var index = clone_header_menu.indexOf('help')
                    if (index !== -1) {
                      clone_header_menu.splice(index, 1);
                    }
                  }

                  return clone_header_menu.includes(menu) ?
                    this.getMenuButton(menu)
                    : <NavItem
                      key={menuDetails.value}
                    >
                      <CustomMenu
                        menu={menuDetails}
                        closeNavbar={this.closeNavbar}
                      >
                      </CustomMenu>
                    </NavItem>
                })}
              </ul>
            </Collapse>
          </Navbar>
          :
          <Navbar expand="md" className="container">
            <NavLink to="/dashboard" className="navbar-brand">
              <img className="logo-default" src={IMAGES_LOGO_WHITE} alt="logo" />
            </NavLink>
            <Collapse isOpen={!this.state.collapsed} navbar>
              <ul className="ml-auto navbar-nav">
                {this.state.menus.filter(m => ThemeConstants.MENUS[m].locator === "header").map((menu) => {
                  let menuDetails = ThemeConstants.MENUS[menu]

                  return Constants.CUSTOM_HEADER_MENUS.includes(menu) ? this.getMenuButton(menu)
                    : <NavItem
                      key={menuDetails.value}
                    >
                      <CustomMenu
                        menu={menuDetails}
                        onClick={this.closeNavbar}
                      >
                      </CustomMenu>
                    </NavItem>
                })}
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav onContextMenu={(e) => e.preventDefault()}>
                    <i className="fa fa-bars"></i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {this.state.menus.filter(m => ThemeConstants.MENUS[m].locator === "dropdown").map((menu) => {
                      let menuDetails = ThemeConstants.MENUS[menu]

                      var clone_header_menu = Constants.CUSTOM_HEADER_MENUS;

                      if (!SHOW_WELCOME_POPUP || Constants.WELCOME_MODAL_PRIORITY < 1) {
                        var index = clone_header_menu.indexOf('help')
                        if (index !== -1) {
                          clone_header_menu.splice(index, 1);
                        }
                      }

                      return clone_header_menu.includes(menu) ? this.getMenuButton(menu)
                        : <DropdownItem
                          key={menuDetails.value}
                        >
                          <CustomMenu
                            menu={menuDetails}
                          >
                          </CustomMenu>
                        </DropdownItem>
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ul>
            </Collapse>
          </Navbar>}
      </div>
    )
  }
}

Header.contextType = ModalControllerContext

export function NavHeader() {
  return window.clearout["header"]
}

const mapStateToProps = (state) => {
  return {
    appReducer: state.appReducer
  }
}

export default connect(mapStateToProps)(Header)
