import _ from 'lodash'

import { updateUser } from '../components/user'
import ApiService from './apiservice'
import { setPricingSlabs } from '../appReducer'
import { updateUserSettings } from '../components/user_settings/userSettingsReducer'
class CommonApiCalls {

  updateUserProfile = (user, dispatch) => {
    const userDetails = {
      credits: user.credits,
      email: user.email,
      name: user.name,
      address: user.address,
      locale: user.locale,
      org_credits: user.org_details && user.org_details.credits,
      org_website_url: user.org_details && user.org_details.org_website_url,
      org_tel: user.org_details && user.org_details.org_tel,
      is_valid_card: user.is_valid_card,
      isValidCard: user.is_valid_card,
      show_daily_limits: user.show_daily_limits,
      limits: user.limits,
      org_limits: user.org_details && user.org_details.limits,
      current_plan: user.subscription ? user.subscription : {},
      phone_number: user.phone_number,
      activated_on: user.activated_on,
      recur: user.recur ? user.recur : null,
      org_recur: user.org_details && user.org_details.recur,
      has_password: user.has_password
    }
    dispatch(updateUser(userDetails))
  }

  listSlabs = (dispatch) => {
    ApiService.listSlabs()
      .then((res) => {
        if (res.status === 'success') {

          let payload = {
            slabs: res.data.slab,
            minCredit: res.data.min_credit
          }
          dispatch(setPricingSlabs(payload))
        }
      })
      .catch((err) => {
        console.log('list slabs api erred', err)
      })
  }

  updateUserSettings = (user, dispatch) => {
    let low_credit_notification = _.get(user, 'settings.low_credit_balance', {})

    if (user.user_type === 'organization' && user.user_role === 'owner') {
      low_credit_notification = _.get(user, 'org_details.settings.low_credit_balance', {})
    }

    let userSettings = {
      include_role_email: user.settings.email_finder.include_role_email,
      auto_pilot_status: _.get(user, 'settings.auto_pilot_status', false),
      notifications: user.settings.notifications,
      can_show_email_verify_mode: user.can_show_email_verify_mode,
      default_email_verify_mode: user.default_email_verify_mode,
      low_credit_notification,
      person_enrich_settings: _.get(user, 'settings.prospect.enrich.person'),
      company_enrich_settings: _.get(user, 'settings.prospect.enrich.company')
    }
    dispatch(updateUserSettings(userSettings))
  }
}

export default new CommonApiCalls()