
const UPDATE_USER_SETTINGS = 'update_user_settings'
const UPDATE_INCLUDE_ROLE_EMAIL_SETTINGS = 'update_include_role_format_settings'
const UPDATE_AUTO_PILOT_SETTINGS = 'update_auto_pilot_settings'
const UPDATE_NOTIFICATION_SETTINGS = 'update-notification-settings'
const UPDATE_LOW_CREDIT_NOTIFICATION_SETTINGS = 'update-low-credit-notification-settings'
const UPDATE_VERIFICATION_OPTIMIZE_SETTINGS = 'update-verification-optimize-settings'
const UPDATE_PROSPECT_ENRICH_SETTINGS = 'update-prospect-enrich-settings'

const initialState = {
  include_role_email: true,
  auto_pilot_status: false,
  notifications: {},
  low_credit_notification: {},
  can_show_email_verify_mode: false,
  default_email_verify_mode: null,
  person_enrich_settings: {},
  company_enrich_settings: {}
}

export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case UPDATE_USER_SETTINGS:
      return Object.assign({}, state, { ...action.payload })

    case UPDATE_INCLUDE_ROLE_EMAIL_SETTINGS:
      return Object.assign({}, state, {
        include_role_email: action.payload.include_role_email
      })

    case UPDATE_AUTO_PILOT_SETTINGS:
      return Object.assign({}, state, {
        auto_pilot_status: action.payload.auto_pilot_status
      })

    case UPDATE_NOTIFICATION_SETTINGS:
      return Object.assign({}, state, {
        notifications: action.payload
      })

    case UPDATE_LOW_CREDIT_NOTIFICATION_SETTINGS:
      return Object.assign({}, state, {
        low_credit_notification: action.payload
      })

    case UPDATE_VERIFICATION_OPTIMIZE_SETTINGS:
      return Object.assign({}, state, {
        default_email_verify_mode: action.payload
      })

    case UPDATE_PROSPECT_ENRICH_SETTINGS:
      return Object.assign({}, state, {
        person_enrich_settings: action.payload.person,
        company_enrich_settings: action.payload.company
      })

    default:
      return state
  }
}

//set the reducer state
export function updateUserSettings(payload) {
  return {
    type: UPDATE_USER_SETTINGS,
    payload
  }
}

export function updateIncludeRoleEmailSettings(payload) {
  return {
    type: UPDATE_INCLUDE_ROLE_EMAIL_SETTINGS,
    payload
  }
}

export function updateAutoPilotSettings(payload) {
  return {
    type: UPDATE_AUTO_PILOT_SETTINGS,
    payload
  }
}

export function updateNotificationSettings(payload) {
  return {
    type: UPDATE_NOTIFICATION_SETTINGS,
    payload
  }
}

export function updateLowCreditNotificationSettings(payload) {
  return {
    type: UPDATE_LOW_CREDIT_NOTIFICATION_SETTINGS,
    payload
  }
}

export function updateVerificationOptimizeSettings(payload) {
  return {
    type: UPDATE_VERIFICATION_OPTIMIZE_SETTINGS,
    payload
  }
}

export function updateProspectEnrichSettings(payload) {
  return {
    type: UPDATE_PROSPECT_ENRICH_SETTINGS,
    payload
  }
}
